/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import HowItWorks from 'components/HowItWorks';
import Benefits from 'components/Benefits';
import Resources from 'components/Resources';
import VideoAndContent from 'components/VideoAndContent';
import ProtectContent from './components/ProtectContent';
import ProtectBanner from './components/ProtectBanner';
import { TITLE, HOWITWORKS, TITLE2, CONTENT, VIDEO_CONTENT } from './constants';
import help from 'models/help';

export default class Protect extends BasePage {
  className = 'ts-Protect';
  title = 'Privacy & Data Protection';
  description = 'Trust Stamp Protect provides an integrated layer of privacy and data protection that maximises functionality of biometric identity systems by enabling secure storage, safe sharing and accurate interrogation without the need to expose sensitive data';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        <Banner theme="fingers" size="small" colored={ true }>
          <h1>Trust Stamp Protect</h1>
        </Banner>

        <ProtectContent />

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ TITLE }
          columnCount={ 3 }
        />

        <ProtectBanner />

        <Benefits sectionTitle={ TITLE2 } content={ CONTENT } />

        { /* video section */ }
        <VideoAndContent content={ VIDEO_CONTENT } colored={ true } />

        { /* resources section */ }
        { /* <Resources>
          <article>
            <h6>RESOURCES</h6>
            <h2>Irreversibly of the (IT)<sup>²</sup> Transform</h2>
            <p>Learn how Trust Stamp’s tokenisation tech maximises privacy and utility of personal data to enhance risk and data protection measures with a frictionless UX</p>
            <Link href="" className="ts-button--primary">Get the Whitepaper</Link>
          </article>
          <aside>
            <img
              src={ asset`/ai/irreversibility-paper.png` }
              alt="Irreversibly of the (IT)² Transform"
              width="400"
            />
          </aside>
        </Resources> */ }
        <Resources>
          <article>
            <h6>RESOURCES</h6>
            <h2>Privacy-Protecting Power of the Irreversibly Transformed Identity Token</h2>
            <p>Learn how Trust Stamp’s tokenisation tech maximises privacy and utility of personal data to enhance risk and data protection measures with a frictionless UX</p>
            <Link to={ ROUTES.WHITEPAPER } className="ts-button--primary">Get the Whitepaper</Link>
          </article>
          <aside>
            <img src={ asset`/ai/privacy-paper-2.png` } alt="Privacy-Protecting Power of the Irreversibly Transformed Identity Token" width="400" />
          </aside>
        </Resources>

      </div>
    );
  }
}
