/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';

export default class Annoucements extends BasePage {
  className = 'ts-Annoucements';
  title = 'Annoucements';
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  // banner
  renderMainSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h2 className={ this.cn`__hero__title` }>
            Annoucements
          </h2>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner section */ }
        { this.renderMainSection() }

        { /* Content Area */ }
        <div className="ts-container">
          <div className={ this.cn`__content` } >
            <h2>TrustStamp Receives DTC Approval</h2>
            <p><strong>Atlanta, GA, November 10, 2020:</strong> T Stamp Inc. dba Trust Stamp is pleased to announce that it has received approval from the Depository Trust Company (“DTC”) to make the Company’s common stock eligible to be electronically cleared and settled through DTC (“DTC Eligibility”).</p>
            <p>Company President Andrew Gowasack said: <em>“we are delighted to have achieved DTC eligibility as it simplifies the process of investors and brokers trading and exchanging our stock in the United States, and also clears the path for our planned listing”</em>.</p>
            <p>DTC is a subsidiary of the Depository Trust and Clearing Corporation, a United States company that manages the electronic clearing and settlement of publicly traded companies.</p>
            <p>DTC Eligibility incorporates an electronic method of clearing securities that speeds up the receipt of stock and cash, and thus accelerates the settlement process for certain investors. DTC is a member of the U.S. Federal Reserve System, a limited-purpose trust company under New York State banking law and a registered clearing agency with the U.S. Securities and Exchange Commission.</p>
          </div>
        </div>
      </div>
    );
  }
}
