/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export const TITLE = 'Why Trust Stamp for Deduplication & Greylist Checks';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Prevent duplicate identities',
    text: 'Go beyond standard KYC processes by identifying faces registered under different names, improving fraud prevention at account opening.',
    slug: 'deduplication-1',
  },
  {
    id: 2,
    title: 'Detect repeat attackers and synthetic identities',
    text: 'Flag and stop identities previously linked to fraud or non-payment by using tokenized biometric searches during onboarding, ensuring that no biometric measurements are stored while still enable face matching.',
    slug: 'white-flag',
  },
  {
    id: 3,
    title: 'Ensure compliance',
    text: 'Satisfy your compliance teams with an identity verification system tailored to meet GDPR, CPRA, PIPEDA, and other privacy regulations.',
    slug: 'star-shield',
  },
  {
    id: 4,
    title: 'Set your own adjudication thresholds',
    text: 'Customize how potential matches are handled with options for manual review or automated scoring for account opening fraud prevention.',
    slug: 'notes',
  },
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'More',
    slug: 'tick',
    list: [
      'Actionable insights',
      'System longevity',
      'Rapid deployment, scalability and adaptability',
      'Cost savings',
    ],
  },
  {
    id: 2,
    title: 'Less',
    slug: 'close',
    list: [
      'Risk to your organisation and users',
      'Time wasted for you and your customers',
      'Computational resources required',
      'Data stored',
    ],
  },
];

export const TITLE2 = 'Intercept Fraudsters Before They Act';

export const CONTENT = [
  {
    id: 1,
    title: 'Data Ingestion',
    text: 'All biometric data, typically in the form of facial images, is ingested into the system. Each image is processed to extract unique biometric features, which are then tokenized using our IT2 technology.',
    slug: 'systems',
  },
  {
    id: 2,
    title: 'Tokenization and IT2 Generation',
    text: 'Each biometric feature set is converted into an IT2 (Irreversibly Transformed Identity Token). This ensures that the data is non-reversible and secure, protecting user privacy throughout the deduplication process.',
    slug: 'tokenization',
  },
  {
    id: 3,
    title: '1:N and N:N Matching',
    text: 'The system performs both 1:N and N:N matching, where each token is compared against every other token in the dataset. This comprehensive comparison identifies instances where the same individual may have been enrolled multiple times under different identifiers.',
    slug: 'matching',
  },
  {
    id: 4,
    title: 'Clustering and Association',
    text: 'Once potential duplicates are identified, the system clusters these tokens together, associating them with a single biometric identifier. This ensures that each unique individual in the dataset is represented by one identifier, preventing multiple entries for the same person.',
    slug: 'association',
  }
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'The core technology',
  text: 'Trust Stamp Protect creates pseudonymised token identifiers for accurate, protected and low-footprint identification and deduplication',
  video: 'https://player.vimeo.com/video/499024505',
};
