/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export const TITLE = 'Advanced AI-powered tools and machine learning techniques';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Proof-of-liveness techniques',
    text: '3D face detection examines expected movement and tracked measurements',
    slug: 'pol',
  },
  {
    id: 2,
    title: 'Anti-spoofing algorithms',
    text: 'AI-powered technology analyses the capture to seek evidence of print and device display attacks',
    slug: 'display-attack',
  },
  {
    id: 3,
    title: 'Robust system of checks',
    text: 'Over 20 individual checks block fraudsters from spoofing biometric capture',
    slug: 'multiple-checks',
  },
  {
    id: 4,
    title: 'Constant innovation',
    text: 'With biometrics at the core of our work, Trust Stamp continues to raise the standard for accurate, accessible, and fraud-resistant biometric capture and analysis tools',
    slug: 'continuous-innovation',
  },
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'More',
    slug: 'tick',
    list: [
      'Actionable insights',
      'System longevity',
      'Rapid deployment, scalability and adaptability',
      'Cost savings'
    ]
  },
  {
    id: 2,
    title: 'Less',
    slug: 'close',
    list: [
      'Risk to your organisation and users',
      'Time wasted for you and your customers',
      'Computational resources required',
      'Data stored'
    ]
  }
];

export const TITLE2 = 'Safeguarding Customer Accounts with Biometric Multi-Factor Authentication';

export const CONTENT = [
  {
    id: 1,
    title: 'Beyond Passwords and One-Time Passcodes: Secure Authentication with an Additional Factor',
    text: "Trust Stamp's Biometric Multi-Factor Authentication (BMFA) integrates seamlessly with financial institutions' existing systems, adding a layer of security that verifies customers' identities using their unique biometric data. From facial recognition to voice analysis, our sophisticated BMFA solutions ensure that only the true account holder can access their account or facilitate transactions.",
    slug: 'biometric-mfa',
    slugType: 'jpg',
  },
  {
    id: 2,
    title: 'Enhancing KYC Accuracy and Confidence',
    text: 'In the realm of Know Your Customer (KYC), Trust Stamp\'s Biometric Multi-Factor Authentication provides a robust solution. By integrating advanced biometric verification methods, our BMFA not only strengthens security but also enhances the accuracy and confidence of KYC processes. Financial institutions can rely on Trust Stamp to deliver a secure and streamlined KYC experience for both organizations and their customers.',
    slug: 'conference2',
    slugType: 'jpg',
  },
];


export const VIDEO_CONTENT = {
  id: 1,
  title: 'The core technology',
  text:
    'Trust Stamp Protect creates pseudonymised token identifiers for accurate, protected and low-footprint identification and deduplication',
  video: 'https://player.vimeo.com/video/499024505'
};

export const TITLE3 = 'Tokenized Age with Trust Stamp’s Irreversibly Transformed Identity Token (IT2)';

export const CONTENT2 = [
  {
    id: 1,
    title: 'Streamlined and User-Friendly',
    text: 'Trust Stamp employs cutting-edge AI-based age verification solutions to make age confirmation effortless for users, ensuring compliance with minimum age requirements',
    slug: '01_Streamlined_and_User_Friendly'
  },
  {
    id: 2,
    title: 'Enhancing User Safety',
    text: "Age verification guarantees that only age-appropriate users can access your services or purchase age-restricted goods. Trust Stamp can help protect your business and ensure your users' safety.",
    slug: '02_Enhancing_User_Safety'
  },
];

export const CONTENT3 = [
  {
    id: 1,
    title: 'Automated & Reliable Age Checks',
    text: "Leverage the potential of digital innovation to swiftly and accurately confirm your users' ages in seconds.",
    slug: '03_Automated_Reliable_Age_Checks'
  },
  {
    id: 2,
    title: 'Safeguard Minors',
    text: 'Secure your business and shield minors from potential harm by permitting only users of the appropriate age to access your platform’s products, services, or content.',
    slug: '04_Safeguard_Minors'
  },
  {
    id: 3,
    title: 'Protection for your Brand',
    text: 'Guarantee that age-restricted content is only accessible to authorized users, averting potential liabilities.',
    slug: '05_Protection_for_your_Brand'
  },
];

