import React, { Component } from 'react';

export default class En extends Component {
  render() {
    return (
      <div>
        <h1>
          <u>POWERED BY TRUST STAMP TERMS AND CONDITIONS</u>
        </h1>

        <p>
          <b>Who are you?</b>
        </p>
        <p>
          T Stamp Inc. d/b/a Trust Stamp (referred to below as “Trust Stamp,”
          “we,” or “us”) is an artificial intelligence company focused on
          providing enhanced security through biometric identity authentication
          and verification. “Powered by Trust Stamp” is a service that Trust
          Stamp provides our customers (each a “Verifying Entity” and
          collectively, “Verifying Entities”) that allows the Verifying Entity
          to probabilistically verify the identity of its customer (an “End
          User”) through Trust Stamp’s secure authentication processes.{ ' ' }
        </p>

        <p>
          <b>How this works?</b>
        </p>
        <p>
          “Powered by Trust Stamp” authenticates the identity of an End User
          through comparison of biometric data derived from an image or video of
          the End User to data derived from known image(s) of the End User,
          identity document(s) provided by the End User, or both. This biometric
          authentication process protects both the End User and the Verifying
          Entity against unauthorized account access and identity fraud. When
          you access “Powered by Trust Stamp” to verify your identity to a
          Verifying Entity, you are an “End User.”
        </p>

        <p>
          <b>How do you use biometric data and information?</b>
        </p>
        <p>
          Comparison of biometric measurements serves as the mechanism for both
          verifying the identity of an End User and confirming identity
          document(s) submitted by an End User belongs to that End User. We
          collect and process your raw biometric measurements for that purpose.
          We process your raw biometric measurements, whether from a live image
          or document, through a proprietary process to convert the raw
          measurements into an Irreversibly Transformed Identity Token (IT2)™.
          The IT2 is a unique, tokenized derivative of your raw biometric
          measurements, but cannot be reverse engineered to recreate your raw
          measurements. As used in these Terms, “Biometric Data” includes your
          raw biometric measurements, but does not include the IT2. While the
          process used to create the IT2 is irreversible, the IT2 retains
          properties that can be used to verify your identity on a probabilistic
          basis. In other words, we can compare an IT2 generated currently to
          one generated from your Biometric Data in the past or future to verify
          your identity. We use this proprietary probabilistic comparison
          process to verify your identity for a Verifying Entity.
        </p>

        <p>
          <u>
            <b>
              The Fine Print (<i>very important stuff; don’t tune out now</i>)
            </b>
          </u>
        </p>
        <p>
          <b>
            Be aware that these Terms include, among other things, a binding
            arbitration provision that requires you to submit to binding and
            final arbitration on an individual basis to resolve disputes, rather
            than a jury trial or class action lawsuit.
          </b>
        </p>
        <ol>
          <li>
            <p>
              <u>Acceptance; Privacy Policy.</u> You must be eighteen (18) years
              or older (or the age of legal majority in your jurisdiction of
              residence, if older) to use Powered by Trust Stamp (referred to
              below as the “Services.”) to verify your identity or authenticate
              your identity document(s). By accessing and/or using, or
              continuing to access or use, the Services in any way, you are
              representing that you (a) are at least eighteen (18) years old or
              the minimum age required to enter into a binding legal contract in
              your jurisdiction of residence, and have the right, authority, and
              capacity to enter into these Terms; (b) you agree to and accept
              these Terms, which also incorporate by reference Trust Stamp’s
              Privacy Policy, found here
              https://truststamp.ai/PrivacyPolicy.html (the “Privacy Policy ”),
              each of which may be updated from time to time without notice to
              you as further set forth in each document; (c) if there have been
              material changes to these terms since you last accessed or used
              the Services, acknowledge and agree that your continued access or
              use of the Services constitutes your acceptance of the changed
              Terms; and (d) consent to receive communications from us
              electronically and agree that such electronic communications,
              notices and/or postings satisfy any legal requirements that such
              communications be in writing.
            </p>
          </li>

          <li>
            <p>
              <u>Accessing the Services.</u>
              Subject to your compliance with these Terms, we grant you a
              limited, non-exclusive, non-sublicensable, revocable,
              non-transferable license to access and use the Services. It is a
              condition of your use of the Services that the information you
              provide is correct, current, and complete. Your use of the
              Services is at your own risk and you acknowledge that the process
              used is probabilistic and cannot provide an absolute verification
              of your identity. Any rights not expressly granted herein are
              reserved by Trust Stamp. You are responsible for making all
              arrangements necessary for you to access the Services. The
              Services shall include, without limitation, any services,
              applications, or extensions offered by us that either we provide
              directly to you or you download or receive from a Verifying Entity
              or from third-party application stores, subject to the terms of
              such stores or their providers. To use the Services, you are
              responsible for having compatible hardware, software, and internet
              access. We do not make any representations or warranties with
              respect to the devices you use to access or use the Services,
              including any with respect to the compatibility of any device with
              the Services.
            </p>
          </li>

          <li>
            <b>
              <p>
                <u>
                  Consent for Collection, Processing and Use of Biometric Data.
                </u>
                When you use the Services, we will request that you provide
                image(s) of yourself (i.e., a “selfie”) (your “Verification
                Image”), official, photo-bearing identity document(s) (your
                “Identification Document”), or both. By using the Services, you
                consent to our collection, processing, storage, and use of your
                biometric data derived by us from your Verification Image, your
                Identification Document(s), and known or previously verified
                photographic image(s) of you provided to us by the Verifying
                Entity (your “Identification Image”). We will use your IT2
                derived from your Verification Image, Identification
                Document(s), and Identification Image for the purpose of
                verifying and authenticating your identity as being the same
                person depicted in the Verification Image and on the
                Identification Document(s) or in the Verification Image and the
                Identification Image, depending on the manner through which the
                Verifying Entity has requested that we verify your identity. You
                are not required to provide consent, and we will not collect,
                process, store, or use your Biometric Data or IT2 without your
                consent. However, if you choose not to provide consent, you will
                not be able to verify and authenticate your identity to the
                Verifying Entity using the Services, which may affect your
                subsequent interactions with the Verifying Entity. You will need
                to interact directly with the Verifying Entity to resolve such
                issues.
                <br />
                <br />
                Except as otherwise provided in these Terms, we will not
                disclose or disseminate your Biometric Data or IT2 to anyone
                other than the Verifying Entity and our subsidiaries and
                affiliates without first obtaining your written consent to such
                disclosure or dissemination, unless such disclosure is:
                <br />
                <ol>
                  <li>
                    <p>
                      required by federal, state, or provincial law or municipal
                      ordinance;
                    </p>
                  </li>
                  <li>
                    <p>
                      to report fraudulent use or attempted fraudulent use of
                      the Services to cognizant legal authorities or
                    </p>
                  </li>
                  <li>
                    <p>
                      required pursuant to a valid written command, demand,
                      order, subpoena, warrant, or writ issued by a court of
                      competent jurisdiction or other governmental body lawfully
                      empowered to issue such legal process.
                    </p>
                  </li>
                </ol>
                A copy of these Terms is available at{ ' ' }
                <a
                  href="https://truststamp.ai/TermsAndConditions.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://truststamp.ai/TermsAndConditions.html
                </a>
              </p>
            </b>
          </li>

          <li>
            <p>
              <u>Disclosure of Data to Verifying Entity.</u> A Verifying Entity
              may wish to retain your Verification Image, Identity Document
              Image and extracted data and IT2 (together “Your Data”) for use in
              verifying your identity in the future. You authorize us to
              disclose your IT2, to a Verifying Entity for that purpose. You
              consent to such Verifying Entity sharing Your Data with us in the
              future and to our use of Your Data to: (i) verify your identity
              and (ii) detect illegal or fraudulent activity.. You understand
              and agree that, once transferred to a Verifying Entity, the
              retention, storage, and use of Your Data shall be subject to any
              terms, conditions, and agreements between you and such Verifying
              Entity. You agree that we are not responsible for the acts of such
              Verifying Entity with respect to Your Data as disclosed to the
              Verifying Entity.
            </p>
          </li>

          <li>
            <p>
              <u>
                Consent for Processing and Sharing of Verification Image and
                Identification Document Data.
              </u>
              By providing your Verification Image, you expressly give your
              consent to use, process, and share your Verification Image to the
              Verifying Entity for identity verification purposes. You warrant
              and represent that the Image uploaded is of you and not of any
              third party. You confirm that you have all necessary rights and
              permissions to submit this Image for the stated purposes. By
              providing an image of your Identification Document(s), you
              represent that the you are the lawful owner and user of such
              Identification Document(s) and consent to our use of optical
              character recognition (OCR), digital and radio frequency scanning,
              and other technology to read any data contained in or on your
              Identification Document, which may include, without limitation,
              your name, address, age, date of birth, place of birth,
              citizenship status, eye color, hair color, height, weight, race,
              ethnicity, identity document number, date of issue, or place of
              issue (the “ID Information”). By submitting an image of your
              Identification Document, you are also acknowledging that you
              understand and agree that (1) we may share your ID Information
              including the image of your Identification Document with the
              Verifying Entity, (2) the further use and disposition of your ID
              Information that we transfer to the Verifying Entity shall be
              governed solely by any terms, conditions, and agreements between
              you and such Verifying Entity, (3) to the extent lawful, and
              without identifying you as the subject of such data, we may use
              Your Data for the purpose of the improvement, development, and
              training of our systems and services. This includes but is not
              limited to, the use of the data in machine learning models, data
              analysis, and other research and development processes and (4)
              subject to the additional limitations of liability below, you, on
              your own behalf and on behalf of your heirs and assigns, are
              agreeing to indemnify, release and hold harmless Trust Stamp from
              any and all claims related to the further use and disposition of
              your ID Information, including, without limitation, any claims
              predicated upon (a) the improper use, disclosure, or disposition
              of your ID Information, (b) identity fraud and theft, (c) invasion
              of privacy, or (d) negligent or intentional infliction of
              emotional distress.{ ' ' }
              <b>
                If you do not agree to these limitations, you should NOT submit
                an image of your Identification Document.
              </b>
            </p>
          </li>

          <li>
            <p>
              <u>Data Retention.</u>
              Your Data may be retained consistent with our obligations to a
              Verifying Entity. Verifying Entities set a period for retention of
              all or a part of Your Data. Absent a court order, subpoena,
              warrant, writ, or other legal requirement that we do so, in no
              case will we retain your Biometric Data, IT2, and ID Information
              for longer than thirty-six (36) months. In the meantime you may
              request that we delete Your Data. To request deletion of Your
              Data, submit a request by email to{ ' ' }
              <a href="mailto:privacy@truststamp.net">
                privacy@truststamp.net{ ' ' }
              </a>{ ' ' }
              or by mail to:
              <br />
              <br />
              Data Deletion: Trust Stamp <br />
              1st and 2nd Floors, 3017 Bolling Way NE
              <br />
              Atlanta, GA, 30305
              <br />
              <br />A request that we delete your Biometric Data or IT2 will not
              affect a completed verification. Deletion of your Biometric Data
              or IT2 may take up to ten business days from receipt of the
              request.
            </p>
          </li>

          <li>
            <p>
              <u>Device and Session Data.</u>
              We may also collect data about the device from which the
              Identification Document was transmitted to us (“Device Data”) and
              the internet connection session during which your Identification
              Document, Identification Image, or Verification Image was
              transmitted to us, including the Internet Protocol (IP) address
              (“Session Data”). You consent to our capture and retention of
              Device Data and Session Data, and sharing of such information with
              the Verifying Entity.
            </p>
          </li>

          <li>
            <p>
              <u>Content.</u>
              You agree that, consistent with these Terms, and in reliance on
              your express warranties regarding the data that you send or
              receive through use of the Services, Trust Stamp neither has nor
              exerts any editorial or other subjective control over the content
              of any data, communications or messages which may be sent by the
              Verifying Entity. Trust Stamp exercises no control over
              information re-transmitted by the Verifying Entity, and cannot be
              held responsible for the accuracy, correctness, or legality of
              such information. You are solely responsible for verifying the
              accuracy, legality and suitability of any information and services
              you obtain from a Verifying Entity or from any third parties via
              or through your use of the Services.
            </p>
          </li>

          <li>
            <p>
              <u>Data Storage.</u>
              We will use a reasonable standard of care to store, transmit and
              protect from disclosure any electronic data, including biometric
              data, collected from you. Such storage, transmission, and
              protection from disclosure shall be performed in a manner that is
              the same as or more protective than the way we store, transmit and
              protect from disclosure other confidential and sensitive
              information.
            </p>
          </li>
          <li>
            <p>
              <u>No Guarantee of Service.</u>
              It is our goal to always make the Services available. However,
              there may be times when we need to temporarily or permanently
              modify or disable the Services. We reserve the right to
              discontinue, modify, suspend, or disable, in whole or in part,
              access to the Services or impose usage or access limits on the
              Services, either with or without notice, and in all cases without
              liability. In no event will we be liable for the discontinuance,
              modification, suspension, or disabling of access to the Services
              at any time or for any duration.
            </p>
          </li>
          <li>
            <p>
              <u>Lawful Use Only.</u>
              You may use the Services only for lawful purposes and in
              accordance with these Terms. We are under no obligation to enforce
              the Terms on your behalf against another End User, person or
              entity. You agree not to, and shall not, assist, encourage, or
              enable others to use the Services:
            </p>
            <ol type="a">
              <li>
                <p>
                  for any purpose other than as expressly permitted under these
                  Terms;
                </p>
              </li>
              <li>
                <p>
                  in a manner that is abusive, defamatory, abusive, offensive
                  threatening, invasive of the right of privacy of another
                  person, or could reasonably expected to cause harm to any
                  person or entity;
                </p>
              </li>
              <li>
                <p>
                  to violate any applicable national, regional, federal, state,
                  local or international law or regulation, or to encourage any
                  illegal activity;
                </p>
              </li>
              <li>
                <p>
                  to create, send, knowingly receive, display, transmit, or use
                  any material which:
                </p>
                <ol type="i">
                  <li>
                    <p>
                      infringes any patent, trademark, trade secret, copyright
                      or other intellectual property right(s) of any other
                      person;
                    </p>
                  </li>
                  <li>
                    <p>
                      contains any images of child pornography or child sex
                      abuse material; or{ ' ' }
                    </p>
                  </li>
                  <li>
                    <p>
                      otherwise violates, or contributes to the violation of,
                      the legal rights (including the rights of publicity and
                      privacy) of others or contains any material that could
                      give rise to any civil or criminal liability under
                      applicable laws or regulations, or that otherwise may be
                      in conflict with these Terms or our Privacy Policy; or is
                      likely to deceive or confuse any person;
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  to violate these Terms or any other rules or policies posted
                  by us on our website or otherwise provided to you;
                </p>
              </li>
              <li>
                <p>
                  to transmit any computer viruses, worms, defects, Trojan
                  horses or other malware; or
                </p>
              </li>
              <li>
                <p>
                  to gain unauthorized access to or otherwise violate the
                  security of any computer network.
                </p>
              </li>
            </ol>
            <p>
              You agree not to, and shall not assist, encourage, or enable
              others to:
            </p>
            <ol type="a" start="8">
              <li>
                <p>
                  reverse engineer, decompile, reverse assemble or perform any
                  other operation on or with the Services that would reveal or
                  disclose any source code, trade secrets, or other proprietary
                  information;
                </p>
              </li>
              <li>
                <p>
                  modify, adapt, appropriate, reproduce, distribute, translate,
                  create derivative works or adaptations of, publicly display,
                  sell, trade or in any way exploit the Services, except as
                  expressly authorized by us;
                </p>
              </li>
              <li>
                <p>
                  remove or modify any copyright, trademark or other proprietary
                  rights notice that appears on any portion of the Services or
                  on any materials printed or copied from the Services;
                </p>
              </li>
              <li>
                <p>
                  record, process, harvest, collect or mine information about
                  other users;
                </p>
              </li>
              <li>
                <p>
                  use any robot, script, or other automatic process or means to
                  access the Services for any purpose;
                </p>
              </li>
              <li>
                <p>
                  remove, circumvent, disable, damage or otherwise interfere
                  with any security features or other restrictions of the
                  Services;
                </p>
              </li>
              <li>
                <p>
                  attempt to gain unauthorized access to, interfere with, damage
                  or disrupt any parts of the Services, the server on which the
                  Services is stored or any server, computer or database
                  connected to the Services; Attack the Services via a
                  denial-of-service, distributed denial-of-service (DDoS), or
                  other form of cyberattack; or otherwise attempt to interfere
                  with the proper working of the Services.
                </p>
              </li>
            </ol>
            <p>
              The restrictions above only apply to the extent permissible under
              applicable law. Nevertheless, you agree not to act contrary to
              them (even if your actions are otherwise lawful) without providing
              thirty (30) days’ prior written notice to us at{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>{ ' ' }
              of your intent to do so, together with any information that we may
              reasonably require to provide us an opportunity to accommodate or
              otherwise address your proposed action, which shall be at our sole
              and absolute discretion.
            </p>
          </li>
          <li>
            <p>
              <u>Fraudulent Submissions.</u>
              Any submission or attempted submission to us of a Verification
              Image, Identification Image, or Identification Document that is
              fraudulent, or submitted in an attempt to commit fraud, including,
              without limitation, actions in violation of Paragraph 11 above,
              shall be considered a Fraudulent Submission. Notwithstanding the
              terms above, should you make a Fraudulent Submission, you agree
              that we may retain, process, store and use all submitted data,
              including Biometric Data and ID Information, for a period of up to
              ten (10) years for the purposes of (i) the investigation and
              prosecution of fraudulent activity and (ii) training and improving
              our systems in the detection and prevention of fraud. Upon written
              request to{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>
              , you may request that your data not be used for this purpose.
              However, to honor your request, we may require that you provide us
              with documentation establishing your identity as the lawful owner
              of any data associated with a Fraudulent Submission.
            </p>
          </li>
          <li>
            <p>
              <u>Subpoena Fees.</u>
              If we are required to provide information in response to a court
              order, subpoena, warrant, writ or similar legal or governmental
              inquiry related to your use of the Services, then we may charge
              you for our costs. These fees may include reasonable attorney and
              employee time spent retrieving the records, preparing documents,
              and participating in a deposition or court hearing.
            </p>
          </li>
          <li>
            <p>
              <u>Intellectual Property.</u>
              You shall retain all right, title, and
              interest in and to the material, content, data, and information
              (including your personal information) you submit to us while using
              the Services (collectively, your “Content”). Subject to these
              Terms, you grant us permission to use or disclose your Content
              only as necessary to provide the Services to you or as otherwise
              permitted by these Terms. You represent and warrant that:
            </p>
            <ol type="a">
              <li>
                <p>
                  you own or have otherwise obtained all necessary rights,
                  releases, and permissions to submit all your Content to the
                  Services and to grant the rights granted to us in these Terms;
                </p>
              </li>
              <li>
                <p>
                  you will neither submit nor attempt to authorize the
                  collection, processing, storage, or use of biometric data from
                  any image or Photo ID in which you are not the person
                  depicted; and
                </p>
              </li>
              <li>
                <p>
                  your Content and its submission and use as you authorize in
                  these Terms will not violate (i) any applicable law or
                  regulation or (ii) any third-party intellectual property,
                  privacy, publicity, or other rights.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>
                <u>Termination of Services.</u>
                Trust Stamp may terminate your access to all or any part of the
                Services at any time, with or without cause, with or without
                notice, effective immediately. If you wish to terminate your
                registration and account, you may do so at any time by
                contacting us at{ ' ' }
                <a href="mailto:support@truststamp.net">
                  support@truststamp.net
                </a>
                . Upon any termination, all rights and obligations of the
                parties shall cease, and you shall immediately cease using the
                Services, except that (a) all obligations that accrued prior to
                the effective date of termination, (b) all remedies for breach
                of these Terms, and (c) the provisions of Sections 4, 5, 12, 13,
                16, 17, 18, 19, 20 and 21 shall survive.
              </b>
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>LIMITATION OF LIABILITY.</b>
              </u>
              YOU ACKNOWLEDGE THAT YOUR ACCESS TO AND USE OF THE SERVICES AND
              ANY RELIANCE UPON EITHER ARE AT YOUR SOLE RISK. TO THE MAXIMUM
              EXTENT PERMITTED BY LAW, IN NO EVENT SHALL TRUST STAMP OR ANY OF
              ITS OWNERS, MANAGERS, OFFICERS, DIRECTORS, MEMBERS, SHAREHOLDERS,
              AFFILIATES, SUBSIDIARIES, EMPLOYEES, CONTRACTORS, AGENTS, OR
              REPRESENTATIVES BE LIABLE FOR ANY CONSEQUENTIAL, EXEMPLARY,
              INDIRECT, INCIDENTAL, OR SPECIAL DAMAGES, INCLUDING, WITHOUT
              LIMITATION, DAMAGES FOR LOST PROFITS, DATA EXPOSURE OR LOSS,
              BUSINESS INTERRUPTION AND/OR ANY OTHER COMMERCIAL DAMAGES OR
              LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE SERVICES, WHETHER
              OR NOT TRUST STAMP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES, ON ANY THEORY OF LIABILITY (INCLUDING CONTRACT, TORT
              NEGLIGENCE OR OTHERWISE) ARISING OUT OF, IN CONNECTION WITH OR
              RESULTING FROM THE SERVICES. YOU SPECIFICALLY ACKNOWLEDGE THAT
              COMPUTER DOWNTIME AND VIRUSES ARE RISKS INHERENT IN THE USE OF THE
              INTERNET AND ANY SOFTWARE PRODUCTS, AND YOU AGREE TO ASSUME
              RESPONSIBILITY FOR ANY HARM OR DAMAGES OF ANY KIND OR CHARACTER
              WHATSOEVER RESULTING FROM THESE POSSIBLE HARMS. YOU ALSO
              SPECIFICALLY ACKNOWLEDGE THAT YOU MAY BE DISCLOSING SENSITIVE,
              PRIVATE AND CONFIDENTIAL INFORMATION, INCLUDING BIOMETRIC DATA AND
              BIOMETRIC INFORMATION, ABOUT YOURSELF THROUGH YOUR USE OF THE
              SERVICES AND YOU AGREE TO ASSUME RESPONSIBILITY FOR ANY HARM OR
              DAMAGES OF ANY KIND OR CHARACTER WHATSOEVER RESULTING FROM YOUR
              DISCLOSURE OF SUCH CONTENT. IN NO EVENT SHALL THE OUR TOTAL
              LIABILITY TO YOU FOR ANY AND ALL DAMAGES (OTHER THAN AS MAY BE
              REQUIRED BY APPLICABLE LAW) EXCEED THE GREATER OF EITHER (A) THE
              AMOUNT PAID BY YOU FOR THE SERVICES IN THE SIX (6) MONTHS
              PRECEDING THE APPLICABLE CLAIM, IF ANY, OR (B) ONE HUNDRED DOLLARS
              ($100.00). IT IS THE INTENTION OF YOU AND US THAT THIS PROVISION
              BE CONSTRUED BY A COURT AS BEING THE BROADEST LIMITATION OF
              LIABILITY ALLOWABLE BY APPLICABLE LAW. SOME JURISDICTIONS DO NOT
              ALLOW THE LIMITATION OR EXCLUSION OF INCIDENTAL, CONSEQUENTIAL, OR
              OTHER TYPES OF DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT
              APPLY TO YOU. THESE LIMITATIONS WILL APPLY EVEN IF THE
              ABOVE-STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>MANDATORY BINDING ARBITRATION.</b>
              </u>
              ANY DISPUTE, DIFFERENCE, CONTROVERSY, OR CLAIM ARISING OUT OF OR
              RELATING TO THESE TERMS OR YOUR USE OF THE SERVICES SHALL BE
              RESOLVED BY FINAL AND BINDING ARBITRATION IN ACCORDANCE WITH THE
              RULES OF HENNING MEDIATION AND ARBITRATION SERVICES IN ATLANTA,
              GEORGIA. THE ARBITRATION WILL BE HELD USING ONE ARBITRATOR. THE
              ARBITRATOR MAY AWARD COSTS AND/OR ATTORNEYS’ FEES TO THE
              PREVAILING PARTY. YOU UNDERSTAND THAT ARBITRATION IS FINAL AND
              BINDING AND THAT YOU ARE WAIVING YOUR RIGHTS TO OTHER RESOLUTION
              PROCESSES (SUCH AS COURT ACTION OR ADMINISTRATIVE PROCEEDING).
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>WAIVER OF CLASS ACTION & JURY TRIAL.</b>{ ' ' }
              </u>
              WITH RESPECT TO YOUR USE OF THE SERVICES, REGARDLESS OF WHETHER
              YOU HAVE OBTAINED OR USED THE SERVICES FOR PERSONAL, COMMERCIAL,
              OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN YOUR INDIVIDUAL
              CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
              CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION,
              OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER ALSO APPLIES TO
              CLASS ARBITRATION, AND AN ARBITRATOR MAY NOT CONSOLIDATE MORE THAN
              ONE PERSON’S CLAIMS IN ANY ACTION. YOU AGREE THAT, BY ENTERING
              INTO THESE TERMS, YOU AND TRUST STAMP ARE EACH WAIVING THE RIGHT
              TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE
              ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE
              PROCEEDING OF ANY KIND.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>INDEMNIFICATION.</b>
              </u>
              YOU AGREE TO INDEMNIFY AND HOLD HARMLESS TRUST STAMP, ITS
              SUBSIDIARIES, AND AFFILIATES AND THEIR RESPECTIVE OWNERS,
              MANAGERS, OFFICERS, DIRECTORS, MEMBERS, SHAREHOLDERS, EMPLOYEES,
              CONTRACTORS, AGENTS, LICENSORS AND SUPPLIERS FROM AND AGAINST ANY
              CLAIMS, LIABILITIES, DAMAGES, JUDGMENTS, AWARDS, LOSSES, COSTS,
              EXPENSES AND/OR FEES (INCLUDING REASONABLE ATTORNEYS’ FEES)
              RESULTING FROM (A) YOUR VIOLATION OF THESE TERMS; (B) YOUR USE OR
              ACCESS OF THE SERVICES; (C) YOUR VIOLATION OF ANY THIRD-PARTY
              RIGHTS, INCLUDING, WITHOUT LIMITATION, ANY INTELLECTUAL PROPERTY
              RIGHTS OR RIGHTS OF PUBLICITY; OR (D) ANY CLAIM THAT YOUR USE OF
              THE SERVICES CAUSED DAMAGE TO A THIRD PARTY.
            </p>
          </li>
          <li>
            <p>
              <u>Modification of Terms.</u>
              We reserve the right to modify these Terms, and to add new or
              additional terms or conditions on your use of the Services, for
              any reason, without notice, at any time. Such modifications and
              additional terms and conditions will be effective immediately and
              incorporated into these Terms. Your continued use of the Services
              will be deemed acceptance hereof. We will notify you of material
              changes by requiring you to agree to them prior to future use of
              the Services. The current Terms shall also be available on our
              website.. What constitutes “material changes” will be determined
              at our sole discretion, in good faith and using common sense and
              reasonable judgment.
            </p>
          </li>
          <li>
            <p>
              <u>General Terms.</u>
              These Terms constitute the sole and entire agreement between you
              and Trust Stamp with respect to the Services and supersede all
              prior and contemporaneous understandings, agreements,
              representations and warranties, both written and oral, with
              respect to the Services. No waiver of these Terms by us shall be
              deemed a further or continuing waiver of such term or condition or
              any other term or condition, and our failure to or delay in
              asserting a right or provision under these Terms shall not
              constitute a waiver of such right or provision. If any provision
              of these Terms is held by a court of competent jurisdiction to be
              invalid, illegal or unenforceable for any reason, such provision
              shall be modified to reflect the parties’ intention or eliminated
              to the minimum extent such that the remaining provisions of the
              Terms will continue in full force and effect. The Terms, and any
              rights or obligations hereunder, are not assignable, transferable
              or sublicensable by you except with our prior written consent, but
              may be assigned or transferred by us without restriction. Any
              attempted assignment by you shall violate these Terms and be void.
              The section titles in these Terms are for convenience only and
              have no legal or contractual effect. You hereby grant Trust Stamp
              the right to take steps reasonably necessary or appropriate to
              enforce and/or verify compliance with any part of these Terms. You
              agree that we have the right, without liability to you, to
              disclose any data and/or information to law enforcement
              authorities, government officials and/or a third party, as we
              believe is reasonably necessary or appropriate to enforce and/or
              verify compliance with any part of these Terms (including, but not
              limited to, our right to cooperate with any legal process relating
              to your use of the Services, and/or a third-party claim that your
              use of the Services is unlawful and/or infringes such third
              party’s rights).
            </p>
          </li>
          <li>
            <p>
              <u>Governing Law and Venue.</u>
              These Terms, and the license granted herein, shall be governed by
              the laws of the State of Georgia, without respect to its conflict
              of laws principles. For any claim(s) to which the mandatory
              arbitration provision contained herein is deemed inapplicable, you
              agree to submit to the personal jurisdiction of the federal and
              state courts located in Atlanta, Georgia. Your use of the Services
              may also be subject to other local, state, federal or
              international laws.
            </p>
            <b>
              <p>
                By voluntarily providing a Verification Image or Identification
                Document, you are acknowledging and agreeing to these Terms and
                are:
              </p>

              <ol type="a">
                <li>
                  <p>
                    representing that you have read, understand, and consent to
                    these Terms in their entirety including all provisions
                    related to the collection, processing, storage, and use of
                    your Biometric Data;
                  </p>
                </li>
                <li>
                  <p>
                    representing that you are the person depicted in the
                    Verification Image; the Verification Image is a live,
                    unaltered image of you captured by the camera of the device
                    being used in the process (i.e. not, e.g., a picture of a
                    picture or a video of a video); your Identification Document
                    is a lawfully produced document validly issued by the
                    jurisdiction reflected thereon on which your image and other
                    data are unaltered;
                  </p>
                </li>
                <li>
                  <p>
                    appointing us as your agent solely for the limited purpose
                    of affixing your electronic signature on these Terms on your
                    behalf and are directing that we affix and associate your IP
                    address and the date and time of your acknowledgement of
                    these Terms as your legally binding electronic signature
                    attesting to your understanding and written acknowledgment
                    and acceptance of these Terms;
                  </p>
                </li>
                <li>
                  <p>
                    agreeing that your continued use of the Services constitutes
                    your ongoing consent to these Terms.
                  </p>
                </li>
              </ol>
            </b>
          </li>
        </ol>
      </div>
    );
  }
}
