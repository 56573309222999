/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { CONTENT } from './constant';
import Icon from 'sf/components/Icon';
import Button from 'sf/components/Button';
import { asset } from 'helpers';
import { downloadFileFromUrl } from 'sf/helpers';

export default class SupportedCountries extends BasePage {
  className = 'ts-SupportedCountries';
  title = 'Cookies';
  // eslint-disable-next-line max-len
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  renderCheck = (value) => {
    return value ? <Icon set="io" size={ 24 } type="ios-checkmark" className={ this.cn`__icon` } /> : ' ';
  }

  downloadPDF = () => {
    downloadFileFromUrl(asset`ai/ts_list_of_supported_documents.pdf`);
  }

  render() {
    // length of CONTENT
    const { length } = CONTENT;
    return (
      <div className={ this.rootcn() }>
        <div className="ts-container">
          <h1>List of supported documents</h1>
          <p>Global Accessibility: Opening Doors to Users in Over { length || '200+' } Countries and Territories</p>
          <Button
            className={ this.cn`__button` }
            onClick={ this.downloadPDF }
          >Download The List of Supported Documents
          </Button>
          <div className={ this.cn`__table-container` }>
            <table>
              <thead>
                <tr>
                  <th>Country / territory</th>
                  <th>Driver's License</th>
                  <th>ID Card</th>
                  <th>Passport</th>
                  <th>Other</th>
                </tr>
              </thead>
              <tbody>
                { CONTENT.map(({ country, dl, IDCard, passport, other }) => {
                  return (
                    <tr key={ country }>
                      <td>{ country }</td>
                      <td>{ this.renderCheck(dl) }</td>
                      <td>
                        { this.renderCheck(IDCard) }
                      </td>
                      <td>{ this.renderCheck(passport) }</td>
                      <td>{ this.renderCheck(other) }</td>
                    </tr>
                  );
                }) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
