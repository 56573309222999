/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import { onFullRender } from 'sf/helpers/domHelper';
import device from 'models/device';
import { ROUTES } from 'constants';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import Resources from 'components/Resources';
import { asset } from 'sf/helpers';
import Card from 'sf/components/Card';
import Process from './components/Demo/Demo';
import Work from './components/WhatWeDo/WhatWeDo';
import { RESULTS_CARDS, BANNER_CONTENT } from './constants';
import OurSolution from './components/OurSolution/OurSolution';

export default class BankCaseStudy extends BasePage {
  className = 'ts-case-study';
  title = 'Client Case Study';
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  state = {};

  componentDidMount() {
    onFullRender().then(() => this.setState({ mainSectionStatus: true }));
    this.syncStateWithModel(device, ['mdUp']);
    this.subscribe('showFixedMenu', this.handleShowFixedMenu);
    this.subscribe('locationChange', () => {
      this.publish('mainLayout:hideFixedMenu');
    });
    this.publish('mainLayout:hideFixedMenu');
  }

  renderBannerSection = () => (
    <section className={ this.cn`__banner` }>
      <div className={ this.cn`__container __banner-container` }>
        <h2 className={ this.cn`__title __banner-title` }> Proven Results </h2>
        <div className={ this.cn`__banner-card-container` }>
          { RESULTS_CARDS.map(this.renderBannerCard) }
        </div>
      </div>
    </section>
  );

  renderBannerCard = ({ title, slug, description }) => (
    <Card
      className={ this.cn`__banner-card` }
      key={ title }
    >
      <img
        alt={ title }
        className={ this.cn`__banner-card-image` }
        src={ `/ai/${slug}.png` }
      />
      <h4 className={ this.cn`__banner-card-title` }>{ title }</h4>
      <p className={ this.cn`__banner-card-copy` }>{ description }</p>
    </Card>
  );


  renderBenefitsSection = () => (
    <section className={ this.cn`__banner` }>
      <div className={ this.cn`__container __banner-container` }>
        <div className={ this.cn`__banner-card-container` }>
          <Card
            className={ this.cn`__banner-card` }
            key="benefits"
          >
            <h6>BENEFITS</h6>
            <h2>Deployment outcomes</h2>
          </Card>

          { BANNER_CONTENT.map(({ id, title, slug, list }) => (
            <Card
              className={ this.cn`__banner-card` }
              key={ id }
            >
              <h4><img src={ asset`ai/${slug}.svg` } alt={ title } width="15px" /> { title } </h4>
              <ul>
                { list.map((item) => (
                  <li key={ item.substr(1, 3) }>{ item }</li>
                )) }
              </ul>
            </Card>
          )) }
        </div>
      </div>
    </section>
  );
  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="case" size="small" colored={ true }>
          <h1>Client Case Study</h1>
          <p>Streamlined onboarding and account recovery with enhanced fraud-prevention methods for an S&P 500 bank client</p>
        </Banner>

        { /* how it works */ }
        <Work />

        { /* proven results section */ }
        { this.renderBannerSection() }

        { /* what we do section */ }
        <OurSolution />

        { /* benefits section */ }
        { this.renderBenefitsSection() }

        { /* how we do it section */ }
        <Process />
      </div>
    );
  }
}
