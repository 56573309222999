/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import Benefits from 'components/Benefits';
import HowItWorks from 'components/HowItWorks';
import Resources from 'components/Resources';
import VerifyContent from './components/VerifyContent';
import { TITLE, CONTENT, TITLE2, HOWITWORKS } from './constant';
import help from 'models/help';

export default class Verify extends BasePage {
  className = 'ts-Verify';
  title = 'Identity Verification';
  description = 'Trust Stamp Verify provides a low-friction way to enhance risk management and data protection measures, while protecting sensitive information from potential identity thieves.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="phone-photo" size="small">
          <h1>Privacy-first Identity Verification</h1>
        </Banner>

        <VerifyContent />

        <Benefits sectionTitle={ TITLE } content={ CONTENT } />

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ TITLE2 }
          columnCount={ 3 }
          colored={ true }
        />

        { /* resources section */ }
        <Resources>
          <article>
            <h6>RESOURCES</h6>
            <h2>Privacy-Protecting Power of the Irreversibly Transformed Identity Token</h2>
            <p>Learn how Trust Stamp’s tokenisation tech maximises privacy and utility of personal data to enhance risk and data protection measures with a frictionless UX</p>
            <Link to={ ROUTES.WHITEPAPER } className="ts-button--primary">Get the Whitepaper</Link>
          </article>
          <aside>
            <img src={ asset`/ai/privacy-paper-2.png` } alt="Privacy-Protecting Power of the Irreversibly Transformed Identity Token" width="400" />
          </aside>
        </Resources>

      </div>
    );
  }
}
