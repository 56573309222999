/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export const TITLE = 'Tokenized Age with Trust Stamp’s Irreversibly Transformed Identity Token (IT2)';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Dual-layer Age Verification: Trust Stamp\'s Comprehensive Approach',
    text: "In addition to Trust Stamp's age estimation technology seamlessly integrates with our identity document verification. By combining a real-time selfie with the age data extracted from an official ID such as a driver’s license, we provide a robust dual-layer age verification mechanism. This comprehensive approach not only validates the authenticity of the user but also cross-references the age declared on the ID with the age estimated from the selfie. The result is an enhanced, reliable, and holistic age verification system that confirms user authenticity and compliance with age-restricted access requirements.",
    slug: 'protect-transform'
  },
  {
    id: 2,
    title: 'Age Estimation & Liveness Detection',
    text: (
      <Fragment>
        Cutting-edge algorithms analyze facial characteristics to accurately
        estimate the user's age.
        <br />
        Advanced liveness detection ensures real-time user presence, preventing
        unauthorized use of photos or displays.
      </Fragment>
    ),
    slug: 'protect'
  },
  {
    id: 3,
    title: 'Dual-layer Verification & Tokenized Age',
    text: (
      <Fragment>
        Age estimation seamlessly integrates with identity document
        verification, combining a real-time selfie with data from an official
        ID.
        <br />
        Trust Stamp's Irreversibly Transformed Identity Token (IT)<sup>
          2
        </sup>{ ' ' }
        provides a secure record of the verified age, confirming user
        authenticity and compliance.
      </Fragment>
    ),
    slug: 'do-more'
  }
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'More',
    slug: 'tick',
    list: [
      'Actionable insights',
      'System longevity',
      'Rapid deployment, scalability and adaptability',
      'Cost savings'
    ]
  },
  {
    id: 2,
    title: 'Less',
    slug: 'close',
    list: [
      'Risk to your organisation and users',
      'Time wasted for you and your customers',
      'Computational resources required',
      'Data stored'
    ]
  }
];

export const TITLE2 = 'Tokenized Age with Trust Stamp’s Irreversibly Transformed Identity Token (IT2)';

export const CONTENT = [
  {
    id: 1,
    title: 'Dual-layer Age Verification: Trust Stamp\'s Comprehensive Approach',
    text: "In addition to Trust Stamp's age estimation technology seamlessly integrates with our identity document verification. By combining a real-time selfie with the age data extracted from an official ID such as a driver’s license, we provide a robust dual-layer age verification mechanism. This comprehensive approach not only validates the authenticity of the user but also cross-references the age declared on the ID with the age estimated from the selfie. The result is an enhanced, reliable, and holistic age verification system that confirms user authenticity and compliance with age-restricted access requirements.",
    slug: 'over_21',
    slugType: 'png',
  },
  {
    id: 2,
    title: 'How Age Verification Benefits Your Business',
    text: 'Age verification is essential for businesses with age-restricted content, goods, and services. For fast-growing sectors such as e-commerce, delivery services, gaming platforms, or self-service vending, ensuring authenticity in age fosters a safer online community. Proper age checks enhance user trust and minimize legal risks, making your platform more reliable.',
    slug: 'over_25',
    slugType: 'png',
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'The core technology',
  text:
    'Trust Stamp Protect creates pseudonymised token identifiers for accurate, protected and low-footprint identification and deduplication',
  video: 'https://player.vimeo.com/video/499024505'
};

export const TITLE3 = 'Tokenized Age with Trust Stamp’s Irreversibly Transformed Identity Token (IT2)';

export const CONTENT2 = [
  {
    id: 1,
    title: 'Streamlined and User-Friendly',
    text: 'Trust Stamp employs cutting-edge AI-based age verification solutions to make age confirmation effortless for users, ensuring compliance with minimum age requirements',
    slug: '01_Streamlined_and_User_Friendly'
  },
  {
    id: 2,
    title: 'Enhancing User Safety',
    text: "Age verification guarantees that only age-appropriate users can access your services or purchase age-restricted goods. Trust Stamp can help protect your business and ensure your users' safety.",
    slug: '02_Enhancing_User_Safety'
  },
];

export const CONTENT3 = [
  {
    id: 1,
    title: 'Automated & Reliable Age Checks',
    text: "Leverage the potential of digital innovation to swiftly and accurately confirm your users' ages in seconds.",
    slug: '03_Automated_Reliable_Age_Checks'
  },
  {
    id: 2,
    title: 'Safeguard Minors',
    text: 'Secure your business and shield minors from potential harm by permitting only users of the appropriate age to access your platform’s products, services, or content.',
    slug: '04_Safeguard_Minors'
  },
  {
    id: 3,
    title: 'Protection for your Brand',
    text: 'Guarantee that age-restricted content is only accessible to authorized users, averting potential liabilities.',
    slug: '05_Protection_for_your_Brand'
  },
];
