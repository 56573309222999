/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Icon from 'sf/components/Icon';

const CHECKBOX_CHECKED = { set: 'io', type: 'android-checkbox-outline' };
const CHECKBOX_UNCHECKED = { set: 'io', type: 'android-checkbox-outline-blank' };

export default class Radio extends BaseComponent {
  className = 'ts-Radio';

  static propTypes = {
    children: PropTypes.node,
    stateLink: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    radioStyle: PropTypes.oneOf(['check', 'radio', 'checkbox']),
  };

  static defaultProps = {
    children: '',
    onChange: noop,
    radioStyle: 'check',
  };

  state = {};

  componentDidMount() {
    if (this.props.radioStyle === 'checkbox') {
      Icon.model.prefetch(CHECKBOX_CHECKED, CHECKBOX_UNCHECKED);
    }
  }

  handleChange = (e) => {
    const [stateOwner, key] = this.props.stateLink;

    stateOwner.setState({
      [key]: this.props.value,
    }, () => {
      this.props.onChange(e);
      this.setValid(true);
    });
  };

  setValid = (status) => { // public
    this.setState({
      isValid: status,
    });
  };

  renderIcon() {
    const [stateOwner, key] = this.props.stateLink;
    const { radioStyle } = this.props;
    const modelValue = stateOwner.state[key];
    const value = modelValue === this.props.value && modelValue !== null;

    const iconProps = {
      className: this.cn`__${radioStyle}-icon`,
      type: radioStyle === 'radio' ? 'circle' : 'check',
      size: radioStyle === 'check' ? 16 : 7,
      key: `icon-${value ? '1' : '0'}`,
    };

    if (radioStyle === 'checkbox') {
      Object.assign(iconProps, {
        size: 24,
        ...(value ? CHECKBOX_CHECKED : CHECKBOX_UNCHECKED)
      });
    } else if (!value) {
      // - for checkbox Icon needs to be always rendered
      // - for `radio` and `check`, we render icon just when checked.
      return null;
    }

    return (
      <Icon { ...iconProps } />
    );
  }

  render() {
    const [stateOwner, key] = this.props.stateLink;
    const { isValid } = this.state;
    const { radioStyle } = this.props;
    const isError = typeof isValid === 'boolean' && !isValid;
    const modelValue = stateOwner.state[key];
    const internalValue = modelValue === this.props.value && modelValue !== null;

    return (
      <div
        className={ this.rootcn({
          '__disabled': this.props.disabled,
        }) }
        role="button"
        tabIndex={ 0 }
        onClick={ this.props.disabled ? noop : this.handleChange }
      >
        <div
          className={ this.cn({
            [`__${radioStyle}`]: true,
            [`__${radioStyle}--selected`]: internalValue,
            [`__${radioStyle}--error`]: isError,
          }) }
        >
          { this.renderIcon() }
        </div>
        <span>{ this.props.children }</span>
      </div>
    );
  }
}
