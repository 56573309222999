/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react';

export const CONTENT = [
  {
    country: 'APEC',
    dl: false,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Abkhazia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Afghanistan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'African Development Bank',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'African Union',
    dl: false,
    IDCard: false,
    passport: true,
    other: true
  },
  {
    country: 'Albania',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Algeria',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'American Samoa',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Andorra',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Angola',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Anguilla',
    dl: true,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Antigua and Barbuda',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Argentina',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Armenia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Aruba',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Australia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Austria',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Azerbaijan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bahamas',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bahrain',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bangladesh',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Barbados',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Belarus',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Belgium',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Belize',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Benin',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bermuda',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bhutan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bolivia, Plurinational State of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Bonaire, Sint Eustatius and Saba',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Bosnia and Herzegovina',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Botswana',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Brazil',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Brunei Darussalam',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Bulgaria',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Burkina Faso',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Burundi',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Cambodia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Cameroon',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Canada',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Cape Verde',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Cayman Islands',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Central African Republic',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Chad',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Chile',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'China',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Colombia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Comesa',
    dl: false,
    IDCard: false,
    passport: false,
    other: true
  },
  {
    country: 'Comoros',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Congo',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Congo, Democratic Republic of the',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Cook Islands',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Costa Rica',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: "Cote d'Ivoire",
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Croatia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Cuba',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Curacao',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Cyprus',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Czech Republic',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Denmark',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Djibouti',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Dominica',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Dominican Republic',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: "Donetsk People's Republic",
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'ECOWAS',
    dl: false,
    IDCard: false,
    passport: false,
    other: true
  },
  {
    country: 'East African Community (EAC)',
    dl: false,
    IDCard: false,
    passport: true,
    other: true
  },
  {
    country: 'Ecuador',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Egypt',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'El Salvador',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Equatorial Guinea',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Eritrea',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Estonia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Eswatini',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Ethiopia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'European Union',
    dl: false,
    IDCard: false,
    passport: true,
    other: true
  },
  {
    country: 'Faroe Islands',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Fiji',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Finland',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'France',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'French Polynesia',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Gabon',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Gambia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Georgia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Germany',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Ghana',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Gibraltar',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Greece',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Greenland',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Grenada',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Guam',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Guatemala',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Guernsey',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Guinea',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Guinea-Bissau',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Guyana',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Haiti',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Holy See (Vatican City State)',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Honduras',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Hong Kong',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Hungary',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'IGAD',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Iceland',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'India',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Indonesia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Interpol',
    dl: false,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Iran, Islamic Republic of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Iraq',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Ireland',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Isle of Man',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Israel',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Italy',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Jamaica',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Japan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Jersey',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Jordan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Kazakhstan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Kenya',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Kiribati',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: "Korea, Democratic People's Republic of",
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Korea, Republic of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Kosovo',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Kuwait',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Kyrgyzstan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: "Lao People's Democratic Republic",
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Latvia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Lebanon',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Lesotho',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Liberia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Libya',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Liechtenstein',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Lithuania',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: "Lugansk People's Republic",
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Luxembourg',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'MERCOSUR',
    dl: false,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Macao',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Madagascar',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Malawi',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Malaysia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Maldives',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Mali',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Malta',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Marshall Islands',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Mauritania',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Mauritius',
    dl: false,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Mexico',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Micronesia, Federated States of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Moldova, Republic of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Monaco',
    dl: false,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Mongolia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Montenegro',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Montserrat',
    dl: false,
    IDCard: false,
    passport: true,
    other: true
  },
  {
    country: 'Morocco',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Mozambique',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Myanmar',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Namibia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Nauru',
    dl: true,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Nepal',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Netherlands',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'New Zealand',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Nicaragua',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Niger',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Nigeria',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Niue',
    dl: true,
    IDCard: false,
    passport: false,
    other: false
  },
  {
    country: 'North Macedonia, Republic of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Northern Cyprus',
    dl: false,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Northern Mariana Islands',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Norway',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'OAS',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'OECS',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Oman',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Pakistan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Palau',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Palestine, State of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Panama',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Papua New Guinea',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Paraguay',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Peru',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Philippines',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Poland',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Portugal',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Puerto Rico',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Qatar',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Romania',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Russian Federation',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Rwanda',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Saint Helena, Ascension and Tristan da Cunha',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Saint Kitts and Nevis',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Saint Lucia',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Saint Martin (French part)',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Saint Vincent and the Grenadines',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Samoa',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'San Marino',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Sao Tome and Principe',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Saudi Arabia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Senegal',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Serbia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Seychelles',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Sierra Leone',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Singapore',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Sint Maarten (Dutch part)',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Slovakia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Slovenia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Solomon Islands',
    dl: true,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Somalia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Somaliland',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'South Africa',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'South Ossetia',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'South Sudan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Sovereign Military Order Of Malta',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Spain',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Sri Lanka',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Sudan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Suriname',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Sweden',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Switzerland',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Syrian Arab Republic',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Taiwan, Province of China',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Tajikistan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Tanzania, United Republic of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Thailand',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Timor-Leste',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Togo',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Tonga',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Transnistria',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'Trinidad and Tobago',
    dl: true,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Tunisia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Turkey',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Turkmenistan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Turks and Caicos Islands',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Tuvalu',
    dl: false,
    IDCard: false,
    passport: true,
    other: false
  },
  {
    country: 'Uganda',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Ukraine',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'United Arab Emirates',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'United Kingdom',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'United Nations Organization',
    dl: true,
    IDCard: true,
    passport: false,
    other: true
  },
  {
    country: 'United States',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Uruguay',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Uzbekistan',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Vanuatu',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Venezuela, Bolivarian Republic of',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Viet Nam',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Virgin Islands, British',
    dl: true,
    IDCard: false,
    passport: true,
    other: true
  },
  {
    country: 'Virgin Islands, U.S.',
    dl: true,
    IDCard: true,
    passport: false,
    other: false
  },
  {
    country: 'Western Sahara',
    dl: false,
    IDCard: true,
    passport: true,
    other: false
  },
  {
    country: 'Yemen',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Zambia',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  },
  {
    country: 'Zimbabwe',
    dl: true,
    IDCard: true,
    passport: true,
    other: true
  }
];
