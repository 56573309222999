import React from 'react';
import is from 'next-is';
import { Route, IndexRoute, Redirect } from 'react-router';
import { compose, withProps } from 'recompose';
import { ROUTES, OLD_ROUTES } from 'constants';
import App from 'layout/App';
import MinimalApp from 'layout/MinimalApp';
import ErrorPage from 'pages/ErrorPage';
import IndexCorporate from 'apps/ai/pages/IndexCorporate';
import ContactUs from 'apps/ai/pages/CorporateContactUs';
import ScheduleDemo from 'apps/ai/pages/ScheduleDemo';
import Unsubscribe from 'apps/ai/pages/CorporateUnsubscribe';
import SharedContactUs from 'apps/ai/pages/CorporateContactUs/Shared';
import SharedMiniContactUs from 'apps/ai/pages/CorporateContactUs/SharedMiniForm';
import AboutUs from 'apps/ai/pages/CorporateAboutUs';
import Vital4 from 'apps/ai/pages/Vital4';
import BiometricEventCheckin from 'apps/ai/pages/BiometricEventCheckin';
import OurMission from 'apps/ai/pages/OurMission';
import Humanitarian from 'apps/ai/pages/Humanitarian';
import BankingAndFintech from 'apps/ai/pages/BankingAndFintech';
import Whitepaper from 'apps/ai/pages/Whitepaper';
import Technology from 'apps/ai/pages/Technology';
import TeamMembersAi from 'apps/ai/pages/CorporateTeamMembers';
import CorporateCareers from 'apps/ai/pages/CorporateCareers';
import CorporatePress from 'apps/ai/pages/CorporatePress';
import CorporateKyc from 'apps/ai/pages/CorporateIndustries/CorporateKyc';
import CorporateSecuredEmail from 'apps/ai/pages/CorporateIndustries/CorporateSecuredEmail';
import CorporateRealEstate from 'apps/ai/pages/CorporateIndustries/CorporateRealEstate';
import CorporateTokenizedIdentity from 'apps/ai/pages/CorporateIndustries/CorporateTokenizedIdentity/CorporateTokenizedIdentity';
import Money2020 from 'apps/ai/pages/Money2020';
import PageFooter from 'components/content-pages/PageFooter';
import LPPageHeaderAi from 'components/LPPageHeader';
import CCPAPrivacyRequest from 'apps/ai/pages/CCPAPrivacyRequest';
import SharedCCPAForm from 'apps/ai/pages/CCPAPrivacyRequest/SharedCCPAForm';
import PrivacyPolicyPage from 'apps/ai/pages/PrivacyPolicyPage';
import TermsAndConditions from 'apps/ai/pages/TermsAndConditions';
import SharedTermsAndConditions from 'apps/ai/pages/SharedTermsAndConditions';
import Annoucements from 'apps/ai/pages/Annoucements';
import Products from 'apps/ai/pages/Products';
import Identify from 'apps/ai/pages/Identify';
import Verify from 'apps/ai/pages/Verify';
import Detect from 'apps/ai/pages/Detect';
import Capture from 'apps/ai/pages/Capture';
import Protect from 'apps/ai/pages/Protect';
import Privtech from 'apps/ai/pages/Privtech';
import PixelPinCCPA from 'apps/ai/pages/PixelPinCCPA';
import CookiesAI from 'apps/ai/pages/CookiesAI';
import Cryptocurrency from 'apps/ai/pages/Cryptocurrency';
import BlogFinTech from 'apps/ai/pages/BlogFinTech';
import BlogBeyondPandemic from 'apps/ai/pages/BlogBeyondPandemic';
import BlogGlobalProgress from 'apps/ai/pages/BlogGlobalProgress';
import BlogDataBreaches from 'apps/ai/pages/BlogDataBreaches';
import BlogCrypto from 'apps/ai/pages/BlogCrypto';
import BlogKBA from 'apps/ai/pages/BlogKBA';
import BankCaseStudy from 'apps/ai/pages/BankCaseStudy';
import DigitalIdentity from 'apps/ai/pages/DigitalIdentity';
import AgeVerification from 'apps/ai/pages/AgeVerification';
import SupportedCountries from 'apps/ai/pages/SupportedCountries';
import AgeEstimation from 'apps/ai/pages/AgeEstimation';
import StableIT2 from 'apps/ai/pages/StableIT2';
import WireFraudPrevention from 'apps/ai/pages/WireFraudPrevention';
import BiometricMFA from 'apps/ai/pages/BiometricMFA';
import TravelHospitality from 'apps/ai/pages/TravelHospitality';
import DeduplicationChecks from 'apps/ai/pages/DeduplicationChecks';

const AppWithLayout = compose(
  withProps({
    layoutProps: {
      FooterComponent: PageFooter,
      HeaderComponent: LPPageHeaderAi
    }
  })
)(App);

const IndexCorporateWithLayout = compose(
  withProps({
    layoutProps: {
      manualHeaderFloat: true
    }
  })
)(IndexCorporate);

const ContactUsThankYou = (props) => <ContactUs thankYou={ true } { ...props } />;
const SharedContactUsThankYou = (props) => (
  <SharedContactUs thankYou={ true } { ...props } />
);
const CCPAThankYou = (props) => <CCPAPrivacyRequest thankYou={ true } { ...props } />;
const PixelPinThankYou = (props) => <PixelPinCCPA thankYou={ true } { ...props } />;
const SharedCCPAThankYou = (props) => (
  <SharedCCPAForm thankYou={ true } { ...props } />
);

const oldRoutesRedirects = [];

Object.keys(OLD_ROUTES).forEach((oldPath) => {
  try {
    if (typeof OLD_ROUTES[oldPath] === 'object') {
      const oldPaths = Object.keys(OLD_ROUTES[oldPath]);
      oldPaths.forEach((oldSubPath) => {
        const from = OLD_ROUTES[oldPath][oldSubPath];
        const to = ROUTES[oldPath][oldSubPath];
        // Case-insensitive comparison
        if (from.toLowerCase() !== to.toLowerCase()) {
          oldRoutesRedirects.push({ from, to });
        }
      });
    } else {
      const from = OLD_ROUTES[oldPath];
      const to = ROUTES[oldPath];
      // Case-insensitive comparison
      if (from.toLowerCase() !== to.toLowerCase()) {
        oldRoutesRedirects.push({ from, to });
      }
    }
  } catch (error) {
    console.error(`Error processing redirection for path '${oldPath}':`, error);
  }
});

export default (
  <Route path="/(index.html)">
    <Route
      reportProblem={ false }
      component={ AppWithLayout }
      className="ts-corporate-landing"
    >
      <IndexRoute component={ IndexCorporateWithLayout } />

      <Redirect from="/Press.html" to="/news.html" />

      { oldRoutesRedirects && oldRoutesRedirects.map((route, index) => {
         return <Redirect key={ index } from={ route.from } to={ route.to } />;
     }) }

      <Route path={ ROUTES.CONTACT_US } component={ ContactUs } />
      <Route path={ ROUTES.SCHEDULE_DEMO } component={ ScheduleDemo } />
      <Route path={ ROUTES.UNSUBSCRIBE } component={ Unsubscribe } />
      <Route path={ ROUTES.CONTACT_US_THANK_YOU } component={ ContactUsThankYou } />
      <Route path={ ROUTES.ABOUT_US } component={ AboutUs } />
      <Route path={ ROUTES.TEAM } component={ TeamMembersAi } />
      <Route path={ ROUTES.TECHNOLOGY } component={ Technology } />
      <Route path={ ROUTES.CAREERS } component={ CorporateCareers } />
      <Route path={ ROUTES.NEWS } exact={ true } component={ CorporatePress } />
      <Route path={ ROUTES.KYC } component={ CorporateKyc } />
      <Route path={ ROUTES.SECURED_EMAIL } component={ CorporateSecuredEmail } />
      <Route path={ ROUTES.REAL_ESTATE } component={ CorporateRealEstate } />
      <Route
        path={ ROUTES.TOKENIZED_IDENTITY }
        component={ CorporateTokenizedIdentity }
      />
      <Route path="/Press/Vital4.html" component={ Vital4 } />
      <Route
        path={ ROUTES.BIOMETRIC_EVENT_CHECKIN }
        component={ BiometricEventCheckin }
      />
      <Route path={ ROUTES.MISSION } component={ OurMission } />
      <Route path={ ROUTES.HUMANITARIAN } component={ Humanitarian } />
      <Route path={ ROUTES.BANKING_AND_FINTECH } component={ BankingAndFintech } />
      <Route path={ ROUTES.WHITEPAPER } component={ Whitepaper } />
      <Route path={ ROUTES.CCPA_FORM } component={ CCPAPrivacyRequest } />
      <Route path={ ROUTES.CCPA_THANK_YOU } component={ CCPAThankYou } />
      <Route path={ ROUTES.SHARED_CCPA_FORM } component={ SharedCCPAForm } />
      <Route
        path={ ROUTES.SHARED_CCPA_THANK_YOU }
        component={ SharedCCPAThankYou }
      />
      <Route path={ ROUTES.PRIVACY_POLICY } component={ PrivacyPolicyPage } />
      <Route
        path={ ROUTES.TERMS_AND_CONDITIONS }
        component={ TermsAndConditions }
      />
      <Route path={ ROUTES.ANNOUCEMENTS } component={ Annoucements } />
      <Route path={ ROUTES.IDENTITY_TOOLS } component={ Products } />
      <Route path={ ROUTES.IDENTIFY } component={ Identify } />
      <Route path={ ROUTES.VERIFY } component={ Verify } />
      <Route path={ ROUTES.TRAVEL_HOSPITALITY } component={ TravelHospitality } />
      <Route path={ ROUTES.DETECT } component={ Detect } />
      <Route path={ ROUTES.CAPTURE } component={ Capture } />
      <Route path={ ROUTES.PROTECT } component={ Protect } />
      <Route path={ ROUTES.PRIVTECH } component={ Privtech } />
      <Route path={ ROUTES.PIXELPINCCPA } component={ PixelPinCCPA } />
      <Route path={ ROUTES.PIXELPIN_THANK_YOU } component={ PixelPinThankYou } />
      { /* <Route path={ ROUTES.DATA_BREACHES } component={ DataBreaches } /> */ }
      <Route path={ ROUTES.COOKIES } component={ CookiesAI } />
      <Route path={ ROUTES.CRYPTO } component={ Cryptocurrency } />
      <Route path={ ROUTES.B_BTA } component={ BlogFinTech } />
      <Route path={ ROUTES.B_PANDEMIC } component={ BlogBeyondPandemic } />
      <Route path={ ROUTES.B_PROGRESS } component={ BlogGlobalProgress } />
      <Route path={ ROUTES.B_BREACHES } component={ BlogDataBreaches } />
      <Route path={ ROUTES.B_CRYPTO } component={ BlogCrypto } />
      <Route path={ ROUTES.B_KBA } component={ BlogKBA } />
      <Route path={ ROUTES.BANK_CASE_STUDY } component={ BankCaseStudy } />
      <Route path={ ROUTES.DIGITAL_IDENTITY } component={ DigitalIdentity } />
      <Route path={ ROUTES.AGE_VERIFICATION } component={ AgeVerification } />
      <Route path={ ROUTES.SUPPORTED_COUNTRIES } component={ SupportedCountries } />
      <Route path={ ROUTES.AGE_ESTIMATION } component={ AgeEstimation } />
      <Route path={ ROUTES.STABLE_IT2 } component={ StableIT2 } />
      <Route path={ ROUTES.BIOMETRIC_MFA } component={ BiometricMFA } />
      <Route path={ ROUTES.DEDUPLICATION_CHECKS } component={ DeduplicationChecks } />
      <Route
        path={ ROUTES.WIRE_FRAUD_PREVENTION }
        component={ WireFraudPrevention }
      />

      { FEATURES.MONEY_2020 && (
        <Route path={ ROUTES.MONEY_2020 } component={ Money2020 } />
      ) }
    </Route>

    <Route component={ MinimalApp }>
      <Route
        path={ ROUTES.SHARED_TERMS_AND_CONDITIONS }
        component={ SharedTermsAndConditions }
      />
      <Route
        path="/SharedTermsAndConditions.html"
        component={ SharedTermsAndConditions }
      />
      <Route path={ ROUTES.SHARED_CONTACT_US } component={ SharedContactUs } />
      <Route
        path={ ROUTES.SHARED_CONTACT_US_THANK_YOU }
        component={ SharedContactUsThankYou }
      />
      <Route
        path={ ROUTES.SHARED_MINI_CONTACT_US }
        component={ SharedMiniContactUs }
      />
    </Route>

    <Route
      reportProblem={ false }
      component={ AppWithLayout }
      className="ts-corporate-landing"
    >
      <Route path="/Error.html" component={ ErrorPage } />
      <Route path="*" component={ ErrorPage } />
    </Route>
  </Route>
);
