import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { components } from 'react-select';

export default class CustomOption extends BaseComponent {
  className = 'ts-Select';

  render() {
    const { isSelected, innerProps } = this.props;
    // To make options selectable with VoiceOver/TalkBack, We should avoid using tabindex="-1".
    const { tabIndex: _, ...rest } = innerProps;

    return (
      <components.Option
        { ...this.props }
        innerProps={ {
          ...rest,
          'role': 'option',
          'aria-selected': isSelected,
        } }
      />
    );
  }
}
