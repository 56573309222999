const React = require('react');
const PropTypes = require('prop-types');
const atom = require('atom-js');

const BaseComponent = require('sf/components/BaseComponent');

module.exports = class UploadFallbackNode extends BaseComponent {
	static propTypes = {
		webcam: PropTypes.object,
		capture: PropTypes.bool,
		className: PropTypes.string,
		communicationChannel: PropTypes.string,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		capture: true,
		disabled: false,
		className: '',
	};

	state = this.syncStateWithModelInitial(this.props.webcam.params, [
		'camera',
		'capture_mode',
		'cssPrefix',
		'live',
	]);

	handleChange = (...args) => {
		const { webcam } = this.props;

		const uploadHandler = this.state.capture_mode === webcam.constants.CAPTURE_MODE_PHOTO
			? webcam.handleImageInput
			: webcam.helpers.videoRecorder.handleFileFallbackVideoInput;

		if (this.props.onChange) {
			this.props.onChange(...args);
		}

		return uploadHandler.apply(webcam, args);
	};

	render({ webcam }, { capture_mode, camera }) {
		if (!camera) {
			return;
		}
		const accept = webcam.constants.MODE_ACCEPTS[capture_mode];
		const capture = webcam.constants.WEBRTC_CAMERAS[camera];

		return (
			<div
				className={ `${this.state.cssPrefix}__upload-fallback ${this.props.className}` }
				{ ...this.pickProps() }
			>
				<input
					disabled={ this.props.disabled }
					type="file"
					accept={ accept }
					capture={ this.props.capture ? capture : false }
					onChange={ this.handleChange }
					data-channel={ this.props.communicationChannel }
				/>
			</div>
		);
	}
};
