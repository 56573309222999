/* This page is to display the CCPA form for PixelPin website.
The page will match the styles/content of PixelPin website including the header and footer
The reason for the roundabout is because we would either require a backend person
with .php knowledge to build a form in PixelPin (ProcessWire framework) or
buy an expensive widget (FormBuilder) */

import React from 'react';
import BasePage from 'pages/BasePage';
import { ROUTES } from 'constants';
import Header from './components/Header';
import BannerPixelPin from './components/BannerPixelPin';
import Footer from './components/Footer';
import CCPAForm from './CCPAForm';

export default class PixelPinCCPA extends BasePage {
  className = 'ts-PixelPinCCPA';
  title = 'PixelPin CCPA';
  // eslint-disable-next-line max-len
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  static defaultProps = {
    thankYou: false,
  }

  state = {};

  // class required to hide ts.ai header & footer
  componentDidMount() {
    document.body.classList.add('pixelpin');
  }

  // class required to show ts.ai header & footer
  componentWillUnmount() {
    document.body.classList.remove('pixelpin');
    super.componentWillUnmount();
  }

  performSuccessRedirect = () => {
    this.navigate(ROUTES.PIXELPIN_THANK_YOU);
  }

  // form section
  renderFormSection() {
    if (this.state.isMessageSent || this.props.thankYou) {
      return (
        <div className={ this.cn`__confirmation` }>
          <h3
            className={ this.cn`__form__title` }
            ref={ (ref) => ref && scrollTo(ref, 250, 250) }
          >
            Thank you for contacting us.
          </h3>
          <p>
            Your message has been successfully sent. We will reply as soon as possible.
          </p>
        </div>
      );
    }

    return (
      <CCPAForm onDone={ this.performSuccessRedirect } />
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <section className={ this.cn`__hero` }>

          <Header />

          <BannerPixelPin />

        </section>

        <div>
          { this.renderFormSection() }
        </div>

        <Footer />

      </div>
    );
  }
}
