/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import { Link } from 'react-router';

export default class BlogKBA extends BasePage {
  className = 'ts-BlogKBA';
  title = 'Blog KBA';

  renderContent() {
    return (
      <section>
        <div className="ts-container">
          <h2>Knowledge Based Authentication</h2>
          <div className={ this.cn`__info` }>
            June 28, 2021<br />
            Andrew Gowasack
          </div>
          <h3><u>What is authentication?</u></h3>
          <p>Authentication is the process of proving that something is genuine. While the term can apply to establishing the authenticity of a fact, document, device, or other point of trust, we use it here to describe the process of using knowledge based authentication in establishing the identity of a person.</p>
          <h3>Knowledge based authentication is everywhere</h3>
          <p>Knowledge based authentication, or KBA, uses “what you know” to verify your identity. KBA conceptually requires an individual to possess and share information that is only known by that individual in order to prove that they are who they say they are.</p>
          <p>It is a security measure that enables users to prove their identity when challenged. How many times have you logged into an account with a username and password today? Have you ever entered your mother’s maiden name to be able to reset a forgotten password? These are all examples of knowledge based authentication.</p>
          <p>There are two types of KBA, static and dynamic.</p>
          <ol>
            <li>
              <strong>Static KBA | shared secrets</strong>
              <p>Static KBA is the most common type of authentication used today. On initial enrollment or account registration, an individual provides information that will then be used to grant access or reset credentials in the future. This information is shared between the organization providing the service and the end user. The user provides this information each time they authenticate, and the organization concurrently retrieves it to verify the user’s provided credentials.</p>
            </li>
            <li>
              <strong>Dynamic KBA | out-of-wallet questions</strong>
              <p>Dynamic KBA methods are often referred to as out-of-wallet questions because the answers are not generally found in a person’s wallet. Dynamic KBA differs from static KBA in that it does not require the user to provide pre-determined information on enrollment into a system, but instead generates questions and answers pertaining to the user from public and private data including marketing data and credit reports. After the user provides general identity information like name and date of birth, the organization retrieves records and generates questions and answers to authenticate the identity of the user.</p>
            </li>
          </ol>
          <h3><u>Why is using KBA alone no longer enough?</u></h3>
          <ol>
            <li>
              <strong>Information that is supposed to be “private” may be easily accessed by people that are not the genuine user.</strong>
              <p>With the sheer amount of information on any given person available online through social, public records, and past exposures, a bad actor may be able to provide the answer to “What is the name of your first pet?” just as easily as you can. A quick search on <Link href="https://haveibeenpwned.com/" target="_blank" rel="noopener noreferrer">https://haveibeenpwned.com/</Link> can show some of the data breaches associated with your email address where some underlying KBA information may have been leaked.</p>
            </li>
            <li>
              <strong>The ubiquity of KBA has led to centralized data of personal user information and credentials.</strong>
              <p>Many organizations store all user credentials on one centralized database, leading to devastating data breaches that expose vast amounts of personal data, such as the <Link href="https://krebsonsecurity.com/2013/10/adobe-breach-impacted-at-least-38-million-users/" target="_blank" rel="noopener noreferrer">2013 Adobe breach</Link> which impacted around 38 million users.</p>
              <p>There have been so many data breaches which means that knowing your mother’s maiden name or which street you lived on is no longer a reliable way to prove that you are who you say you are.</p>
            </li>
          </ol>
          <h3><u>Are your passwords secure?</u></h3>
          <p>Although password use is ubiquitous and works on any device, it poses a number of security challenges.</p>
          <ol>
            <li>
              <strong>The vast number of accounts that each person maintains makes passwords extremely challenging to manage.</strong>
              <p>One study shows that the average person has 90 online accounts, nearly all of which rely on KBA! If one of your passwords is exposed in an account takeover attack, how many other accounts can be compromised along with it?</p>
            </li>
            <li>
              <strong>Even with best practices for password use and security in place, KBA credentials can easily be given away to bad actors using social engineering schemes.</strong>
              <p>Personal credentials are exposed every day by unknowing victims of phishing and other social engineering schemes</p>
            </li>
            <li>
              <strong>Poor balance between security and usability leads to insecure credentials, frustrated users, and vulnerabilities that can affect both the organization and individual.</strong>
              <p>While weak passwords are easily guessed, strong passwords can be easily forgotten. <Link href="https://www.gartner.com/en/documents/2174615/when-knowledge-based-authentication-fails-and-what-you-c" target="_blank" rel="noopener noreferrer">U.S. Gartner</Link> reported an average of 10% to 15% failure rate on KBA. <Link href="https://www.keepersecurity.com/blog/2017/01/13/most-common-passwords-of-2016-research-study/" target="_blank" rel="noopener noreferrer">Research from Keeper Security</Link> revealed that up to 17% of people use the password "123456" to 'secure' their accounts, while 50% use one of the top 25 most common passwords.</p>
              <p>When cybercriminals can easily compromise or guess credentials and genuine users are not able to remember them, KBA fails to serve the primary function – authentication! In fact, in last year’s <Link href="https://pages.nist.gov/800-63-FAQ/" target="_blank" rel="noopener noreferrer">NIST Special Publication on Digital Identity Guidelines</Link>, KBA was no longer considered an acceptable authenticator because of its unacceptably high risk of successful use by an attacker.</p>
            </li>
          </ol>
          <h3><u>Room for improvement</u></h3>
          <p>In the case of passwords, it is difficult to balance security and convenience. Instead of protecting user accounts, authentication requirements can become a barrier to accessing services. Companies are increasingly implementing multi-factor authentication processes as the norm to add another layer of security and assurance to identity authentication. We see this happening with widespread adoption of <Link href="https://truststamp.ai/Verify.html" target="_blank" rel="noopener noreferrer">biometric identity verification</Link> solutions in the <Link href="https://www.biometricupdate.com/202009/four-industries-that-are-reaping-the-benefits-of-biometric-identity-verification" target="_blank" rel="noopener noreferrer">financial services and healthcare industries</Link> for example, where privacy, security, and data protection are critical for <Link href="https://truststamp.ai/kyc.html" target="_blank" rel="noopener noreferrer">regulatory compliance</Link> and user satisfaction.</p>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="numbers" size="small">
          <h1>Digital Identity Blog</h1>
        </Banner>

        { this.renderContent() }

      </div>
    );
  }
}
