/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import HowItWorks from 'components/HowItWorks';
import Benefits from 'components/Benefits';
import ProtectContent from './components/PrivtechContent/PrivtechContent';
import { TITLE, HOWITWORKS, TITLE2, CONTENT, SUBTEXT2 } from './constants';

export default class Protect extends BasePage {
  className = 'ts-Protect';
  title = 'Privtech - Privacy-First Solutions';
  description =
    'Trust Stamp empowers government agencies to provide authenticated users with secure digital access with Privtech Certified ™ technology. ';

  render() {
    return (
      <div className={ this.rootcn() }>
        <Banner theme="globe" size="small">
          <h1>Privtech™ <br /> Privacy-First Solutions</h1>
        </Banner>

        <ProtectContent />

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ TITLE }
          columnCount={ 4 }
          subTitle="Products"
        />

        { /* <ProtectBanner /> */ }

        <Benefits
          sectionTitle={ TITLE2 }
          sectionSubText={ SUBTEXT2 }
          content={ CONTENT }
          colored={ true }
        />
      </div>
    );
  }
}
