/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { throttle } from 'lodash';
import { Link } from 'react-router';
import { asset } from 'helpers';
import Button from 'components/Button';
import { YOUTUBE_ASPECT_RATIO } from 'constants';
import BasePage from 'pages/BasePage';
import { getWindowWidth } from 'sf/helpers/domHelper';
import device from 'sf/models/device';
import SocialMediaPin from '../SocialMediaPin/SocialMediaPin';
import {
  ARTICLES,
  PRESS_TYPES,
  EVENTS_TO_ATTEND,
  EVENTS_TO_SPEAK,
} from './constants';

const ARTICLES_REVERSED = ARTICLES.reverse();

export default class CorporatePress extends BasePage {
  className = 'ts-CorporatePress';
  title = 'News';
  description = 'Read about Trust Stamp’s latest news. ';

  state = {
    articlesToShowCount: 12,
    articleList: 18,
    articlesLength: 0,
  };

  componentDidMount() {
    this.syncStateWithModel(device, ['smUp', 'xsmUp']);
    this.calculateImageWidthThrottled();
    this.addEventListener(window, 'resize', this.calculateImageWidth);
  }

  handleAddingArticle() {
    this.setState({
      articlesLength:
      (this.state.articlesToShowCount + this.state.articlesLength + 12 < ARTICLES.length)
        ? this.state.articlesLength + 12
        : ARTICLES.length,
    });
  }

  renderMainSection() {
    return (
      <section className={ this.cn`__hero` } >
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` } >
          <h1 className={ this.cn`__hero__title` } >
            Press and Events
          </h1>
        </div>
      </section>
    );
  }

  calculateImageWidth = () => {
    const xsmDownPadding = 80;
    this.setState({
      imageWidth: getWindowWidth() - xsmDownPadding,
    });
  };

  calculateImageWidthThrottled = throttle(this.calculateImageWidth, 50);

  getImageDimensions = () => {
    const { xsmUp, smUp } = this.state;
    switch (true) {
      case smUp:
        return { width: 353, height: 251 };
      case xsmUp:
        return { width: 520, height: 370 };
      default:
        return {
          width: this.state.imageWidth,
          height: this.state.imageWidth * YOUTUBE_ASPECT_RATIO,
        };
    }
  }

  renderArticleTile = (article) => {
    const { width, height } = this.getImageDimensions();
    switch (article.type) {
      case PRESS_TYPES.YOUTUBE:
        return (
          <div key={ article.url } className={ this.cn`__item` }>
            <div className={ this.cn`__item-top` }>
              <div className={ this.cn`__player-wrapper` }>
                <iframe
                  className={ this.cn`__player` }
                  width="100%"
                  height="100%"
                  src={ article.url }
                  allow="encrypted-media"
                  allowFullScreen="true"
                  frameBorder="0"
                />
              </div>
              <div className={ this.cn`__item-date` }>{ article.date }</div>
              <div className={ this.cn`__item-title` }>{ article.title }</div>
              <div className={ this.cn`__item-text` }>{ article.text }</div>
            </div>
          </div>
        );
      default:
        return (
          <div key={ article.url } className={ this.cn`__item` }>
            <div className={ this.cn`__item-top` }>
              <Link
                href={ article.url }
                className={ this.cn`__item-photo` }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={ `${article.slug}` }
                  src={ asset`ai/articles/${article.slug}.jpg` }
                />
              </Link>
              <div className={ this.cn`__item-date` }>{ article.date }</div>
              <div className={ this.cn`__item-title` }>{ article.title }</div>
              <div className={ this.cn`__item-text` }>{ article.text }</div>
            </div>
            <Link
              href={ article.url }
              className={ this.cn`__item-button` }
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </Link>
          </div>
        );
    }
  };

  renderOldArticleTile = (article) => {
    return (
      <div key={ article.slug } className={ this.cn`__item` } >
        <div className={ this.cn`__item-date` } > { article.date }  </div>
        <div className={ this.cn`__item-title` } > { article.title }  </div>
        <Link
          href={ article.url }
          className={ this.cn`__item-button` }
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
        </Link>
      </div>
    );
  } ;

  renderArticleList() {
    return (
      <section className={ this.cn`__articles-container` } >
        {
          ARTICLES_REVERSED
            .filter((item) =>
              ARTICLES_REVERSED.indexOf(item) >= 0 &&
              ARTICLES_REVERSED.indexOf(item) < this.state.articlesToShowCount)
            .map(this.renderArticleTile)
        }
        { this.renderOldArticleList() }
      </section>
    );
  }

  renderEventList(events) {
    return events.map((event) => (
      <li key={ event.title } className={ this.cn`__event` }>
        <span className={ this.cn`__event-sub-details` }>{ event.date } | { event.address }</span>
        <Link
          href={ event.url }
          className={ this.cn`__event-title` }
          target="_blank"
          rel="noopener noreferrer"
        >
          { event.title }
        </Link>
        { event.sub && <p>{ event.sub }</p> }
        { event.theme && <p>{ event.theme }</p> }
      </li>
    ));
  }

  renderEvents(event, heading) {
    return (
      <div>
        <h3 className={ this.cn`__events-heading` }>{ heading }</h3>
        <ul className={ this.cn`__events-wrapper` }>
          { this.renderEventList(event) }
        </ul>
      </div>
    );
  }

  renderEventsList() {
    return (
      <section className={ this.cn`__events-container` } >
        { this.renderEvents(EVENTS_TO_ATTEND, 'Trust Stamp is attending:') }
        { this.renderEvents(EVENTS_TO_SPEAK, 'Trust Stamp is speaking at:') }
      </section>
    );
  }

  renderPressEventContent() {
    return (
      <div className={ this.cn`__press-event-container` }>
        { /* { this.renderEventsList() } */ }
        { this.renderArticleList() }
      </div>
    );
  }

  renderOldArticleList() {
    return (
      <section className={ this.cn`__articles-container __articles-container--old` } >
        {
          this.state.articlesLength > 0
            ? <h2 className={ this.cn`__articles-title` } >
              Archives
            </h2>
            : ''
        }
        <div className={ this.cn`__articles-container` } >
          {
            ARTICLES_REVERSED
              .filter((item) =>
                ARTICLES_REVERSED.indexOf(item) >= this.state.articlesToShowCount &&
                ARTICLES_REVERSED.indexOf(item) < this.state.articlesToShowCount +
                this.state.articlesLength)
              .map(this.renderOldArticleTile)
          }
        </div>

        {
          this.state.articlesLength < ARTICLES.length
            ? <Button
                onClick={ () => this.handleAddingArticle() }
                className={ this.cn`__item-button __item-button--more-articles` }
            >
              See more
            </Button>
            : ''
        }
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() } >
        { this.renderMainSection() }
        <SocialMediaPin />
        { this.renderPressEventContent() }
      </div>
    );
  }
}
