/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import BasePage from 'pages/BasePage';
import { asset } from 'helpers';
import Resources from 'components/Resources';
import ResourcesForm from 'components/ResourcesForm';
import CryptoContent from './components/CrypoContent';
import HeroComponent from '../CorporateIndustries/CorporateComponents/CorporateIndustriesHero';
import help from 'models/help';

export default class Cryptocurrency extends BasePage {
  className = 'ts-Cryptocurrency';
  title = 'Cryptocurrency and Digital Assets';
  // eslint-disable-next-line max-len
  description = 'Streamlined compliance for the crypto space';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent title="Cryptocurrency and Digital Assets" extraClass="kyc" />

        <CryptoContent />

        <Resources formLayout={ true }>
          <article>
            <h2>Talk to an industry expert</h2>
            <p>Trust Stamp EVP John Bridge is a career expert in digital currency and financial technology, with a focus on fraud detection and cyber investigations. He has spent 34 years in law enforcement, which included serving as Senior Inspector/Chief Inspector of Financial Surveillance with United States Marshals Service, and he now serves as an active member of the North Carolina Blockchain Initiative.</p>
            <img src={ asset`/ai/john-bridge.png` } alt="Talk to an industry expert" width="200" />
          </article>
          <aside>
            <ResourcesForm />
          </aside>
        </Resources>
      </div>
    );
  }
}
