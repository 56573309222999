/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { ROUTES } from 'constants';

export const TITLE = 'Streamline Operations, Boost Reviews';

export const HOWITWORKS = [
  {
    id: 1,
    title: 'Faster Check-In',
    text: 'Guests verify their identity with a selfie, slashing wait times & paperwork.',
    slug: 'lady-face',
  },
  {
    id: 2,
    title: 'Fraud Prevention',
    text: 'Advanced tech stops fraudsters & keeps your property secure.',
    slug: 'lady-lock',
  },
  {
    id: 3,
    title: 'Compliance Made Easy',
    text: 'Trust Stamp ensures you meet verification regulations.',
    slug: 'shield-check',
  },
  {
    id: 4,
    title: 'Guest Satisfaction Guaranteed',
    text: 'Frictionless experience = happy guests, strong',
    slug: 'satisfaction',
  },
];

export const BANNER_CONTENT = [
  {
    id: 1,
    title: 'More',
    slug: 'tick',
    list: [
      'Actionable insights',
      'System longevity',
      'Rapid deployment, scalability and adaptability',
      'Cost savings',
    ],
  },
  {
    id: 2,
    title: 'Less',
    slug: 'close',
    list: [
      'Risk to your organisation and users',
      'Time wasted for you and your customers',
      'Computational resources required',
      'Data stored',
    ],
  },
];

export const TITLE2 = 'Revolutionizing Check-In for Hotels and Vacation Rental Hosts';
export const SUB_TITLE2 = 'Traditional check-in processes often present significant obstacles for hospitality providers, including long wait times, identity fraud, and compliance with verification laws. Trust Stamp’s advanced identity verification technology effectively addresses these critical issues, ensuring a seamless and secure check-in process for your guests.';

export const CONTENT = [
  {
    id: 1,
    title: 'Reduced In-Person Wait Times',
    text: 'Save time for tired travelers by completing traditional check-ins, requiring identity documents like driver\'s licenses and passports, before guests arrive. Once the guest arrives, they can quickly check-in, significantly reducing in-person wait times.',
    slug: 'wait-times',
  },
  {
    id: 2,
    title: 'Identity Fraud Prevention',
    text: 'Trust Stamp’s tools perform identity deduplication and verify the authenticity of identities to detect and prevent fraudulent attempts. Once verified, a Biometric Token is generated. This irreversible token ensures secure facial matching while protecting guest privacy and security.',
    slug: 'identity-fraud',
  },
  {
    id: 3,
    title: 'Seamless Compliance with Verification Laws',
    text: 'Trust Stamp ensures your operations comply with identity verification laws, minimizing legal risks and ensuring smooth operations. Hotels receive verified guest information, including a secure image of the guest\'s driver\'s license, providing both convenience and security.',
    slug: 'verification-laws',
  },
];

export const VIDEO_CONTENT = {
  id: 1,
  title: 'The core technology',
  text: 'Trust Stamp Protect creates pseudonymised token identifiers for accurate, protected and low-footprint identification and deduplication',
  video: 'https://player.vimeo.com/video/499024505',
};
