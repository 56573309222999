const React = require('react');
const noop = require('no-op');
const PropTypes = require('prop-types');
const { findDOMNode } = require('react-dom');
const pick = require('lodash/pick');
const is = require('next-is');

const BaseComponent = require('sf/components/BaseComponent');
const device = require('sf/models/device');

const navigator = global.navigator || {};
const ua = navigator.userAgent || '';

function getWindowWidth() {
	return is.browser() ?
		window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		: null;
}

function getWindowHeight() {
	return is.browser() ?
		window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
		: null;
}

function isSamsungDeviceWithStretchProblem() {
/*
	Some devices - mostly Samsung devices have a problem with getUserMedia.
	Image proportion is invalid, and we receive stretched video.

	Based on results from Test for new camera implementation ( https://testing.truststamp.net/res.html )

	We use following approach for all possible cases:

	Samsung Internet 9:
	Front:
		vertical (!): REAL_HEIGHT
		horizontal: REAL_WIDTH lub 1920x1080
	Back:
		vertical: 1920x1080
		horizontal (!): 1920x1080


	Samsung Internet 10+
	Front:
		vertical (!): REAL_HEIGHT
		horizontal: REAL_WIDTH lub 1920x1080
	Back:
		vertical: 1920x1080 lub NO_ASPECT_1920x1080
		horizontal (!): 1920x1080 lub NO_ASPECT_1920x1080


	OTHER:
	Front:
		vertical (!): REAL_HEIGHT
		horizontal: REAL_WIDTH lub 1920x1080
	Back:
		vertical: 1920x1080 lub NO_ASPECT_1920x1080
		horizontal (!): 1920x1080 lub NO_ASPECT_1920x1080
 */
	if (!is.browser()) return false;

	return is.samsungBrowser() || is.samsung();
}

/**
 * Getting user media on some devices causes a bug, when camera stream
 * is black. We are avoiding this problem by setting maxWidth/maxHeight (depending on orientation)
 * to 1920px.
 *
 * TODO: In new browser version in might not occour. Recheck.
 */
const blackScreenDevicesRegexp = /(HUAWEIVTR-L29|SM-G950|\(.*Android.*; SM-.*\)|; Pixel|Nexus 6P)/;

module.exports = class WebRTCComponent extends BaseComponent {
	static propTypes = {
		webcam: PropTypes.object.isRequired,
	};

	static defaultProps = {};

	state = this.syncStateWithModelInitial(this.props.webcam.params, [
		'cssPrefix',
		'live',
		'photoCapabilities',
		'use_ImageCapture',
	]);

	checkCameraAccess() {
		return new Promise((resolve) => {
			const { params } = this.props.webcam;
			const { webcamAccessGranted } = params.get();

			if (!navigator?.permissions?.query) {
				return resolve(webcamAccessGranted);
			}
			// some browsers never resolve permissions query (Safari).
			const timeoutPromise = this.setTimeout(() => {
				resolve(webcamAccessGranted);
			}, 1000);

			navigator
				.permissions
				.query({ name: 'camera' })
				.then(({ state }) => {
					timeoutPromise.cancelled = true;
					const result = state === 'granted';
					params.set({ webcamAccessGranted: result });
					resolve(result);
				}).catch(() => {
					resolve(webcamAccessGranted);
				});
		});
	}

	componentDidMount() {
		// const adapterURL = `${webcam.params.get('webcam_path')}adapter.js`;
		// getScript(adapterURL, {
		// 	cache: true,
		// 	crossOrigin: 'anonymous',
		// }).then(() => {
		// 	this.startupWebRTC();
		// });

		this.startupWebRTC();
	}

	getConstraits(cameraInfs, track) {
		const { webcam } = this.props;
		const { params } = webcam;

		if (!params.get('webcamAccessGranted') || (is.iOS() && !track)) {
			if (is.desktop()) {
				return true; // video: true
			}
			return {
				// NOTE: don't use `exact:` here. That doesnt work good on Android 13+
				facingMode: webcam.constants.WEBRTC_CAMERAS[params.get('camera')],
			};
		}

		const {
			STANDARD_RESOLUTIONS,
			CAPTURE_MODE_VIDEO,
			CAPTURE_MODE_PHOTO,
			DETECTION_MODE_FACING_MODE,
		} = webcam.constants;

		let maxWidth = 9999;

		const androidVersion = is.android(true);
		const isVideoCapture = params.get('capture_mode') === CAPTURE_MODE_VIDEO;
		const cameraInf = cameraInfs[params.get('cameraId')] || {};
		let { capabilities } = cameraInf;
		if (track?.getCapabilities) {
			capabilities = track.getCapabilities();
			if (process.env.NODE_ENV !== 'production' && params.get('verbose')) {
				// say hello to Safari @ iOS
				if (!cameraInfs.capabilities) {
					webcam.dispatch('detectedVapabilities', capabilities);
				}
			}
		}

		if (androidVersion && isVideoCapture) {
			// android-only optimisationon so video is not lagging
			//
			// NOTE: following code fails when maxWidth is set too low
			// on some devices (Pixel XL, Huawei p8 lite, Huawei P10), Android 5 devices
			if (androidVersion <= 6) {
				maxWidth = 1280;
			} else if (androidVersion === 7) {
				maxWidth = 1680;
			} else if (androidVersion >= 8) {
				maxWidth = 1920;
			}
		}
		if (blackScreenDevicesRegexp.test(ua)) {
			maxWidth = 1920;
		} else if (isVideoCapture) {
			maxWidth = 960;
		}

		if (is.iOS15()) {
			// save some memory to prevent memory leaks (known issue on iOS15)
			// https://developer.apple.com/forums/thread/687866
			// Limiting max width is also to release some memory
			maxWidth = 2048;
		}

		let videoConstraints = {
			deviceId: cameraInf.deviceId, // some devices accept this
			sourceId: cameraInf.deviceId, // for other devices we need to provide sourceId instead
		};

		// const height = getWindowHeight();
		// const width = getWindowWidth();
		// Aspect ratio is a bit stupid and extremally annoying for sure.
		// - In old Chrome (before 72) aspectRatio was logic and usable. 1920/1080 would bring horizontal and 1080/1920 would
		//   bring vertical image. Unfortunetely they changed API, and now 1920/1080 returns horizontal video on horizontal rotation,
		//   and vertical on vertical rotation.
		// - in Safari@iOS. When unsupported aspect ratio is passed, camera stops working. Just like that with black screen and no errors.
		// - In Safari@iOS aspectRatio does not work on front camera. Switching camera is not working there
		// - on Samsung when aspect ratio is not provided, camera preview is stretched and sometimes square.
		// - on devices with 21:10 aspect ratio, we are getting wrong aspect ratio (2.1). 2 is max

		// const aspectRatio = (is.mobile() || is.tablet())
		// 	? Math.max(Math.min(Math.max(width, height) / Math.min(width, height), 2), 0.6)
		// 	: undefined;
		videoConstraints.aspectRatio = device.vertical()
			? 9 / 16
			: 16 / 9;

		const maxWidthFromCapabilities = capabilities?.width?.max;

		if (maxWidthFromCapabilities) {
			const ideal = Math.min(
				params.get('dest_width'),
				Math.max(device.getRealScreenWidth(), device.getRealScreenHeight()),
				maxWidth,
				maxWidthFromCapabilities,
			);

			videoConstraints.width = {
				min: STANDARD_RESOLUTIONS[0],
				ideal,
				max: maxWidthFromCapabilities,
			};
			videoConstraints.height = {
				min: videoConstraints.width.min * videoConstraints.aspectRatio,
				ideal: ideal * videoConstraints.aspectRatio,
				max: maxWidthFromCapabilities * videoConstraints.aspectRatio,
			};

			if (device.horizontal()) {
				// modern devices on vertical mode switches width and height.
				// When requesting `width: { ideal: 750 }` we're getting `height: 750`
				const tmp = videoConstraints.height;
				videoConstraints.height = videoConstraints.width;
				videoConstraints.width = tmp;
			}
		} else {
			// http://stackoverflow.com/a/27444179/2590921
			const constraintsAsArray = Object
				.entries(videoConstraints)
				.map(([key, value]) => ({ [`${key}`]: value }));
			const destWidthModifier = params.get('capture_mode') === CAPTURE_MODE_VIDEO ? 1 : 1.3;

			const allSizes = [...STANDARD_RESOLUTIONS].reverse().reduce((result, minWidth) => {
				if (minWidth <= maxWidth && minWidth <= destWidthModifier * params.get('dest_width')) {
					result.push({ minWidth });
				}
				return result;
			}, constraintsAsArray);

			videoConstraints = {
				optional: allSizes,
			};
		}

		if (params.get('cameraDetectionMode') === DETECTION_MODE_FACING_MODE) {
			/**
			 * DETECTION_MODE_FACING_MODE:
			 * This is less powerful method of selecting camera.
			 * In this method it's impossible to detect if camera is wide angle or not.
			 *
			 * DETECTION_MODE_LABELS is better and usually available right after user grants
			 * camera access.
			 */
			delete videoConstraints.deviceId;
			delete videoConstraints.sourceId;
			videoConstraints.facingMode = {
				exact: webcam.constants.WEBRTC_CAMERAS[params.get('camera')],
			};
		}

		if (is.iOS()) {
			delete videoConstraints.aspectRatio;
		}

		if (
			isSamsungDeviceWithStretchProblem()
			&& params.get('capture_mode') === CAPTURE_MODE_PHOTO
		) {
			if (device.horizontal()) {
				videoConstraints.width = { min: 320, ideal: 1920 };
				videoConstraints.height = { min: 320, ideal: 1080 };
			} else if (params.get('camera') === webcam.constants.CAM_FRONT) {
				videoConstraints.height = { min: 320, ideal: device.getRealScreenWidth() };
				videoConstraints.width = { min: 320, ideal: device.getRealScreenHeight() };
			} else {
				videoConstraints.width = { min: 320, ideal: 1920 };
				videoConstraints.height = { min: 320, ideal: 1080 };
			}
		}

		if (capabilities) {
			// sanity checks for constraints we picked
			const hasOwn = (obj, value) => (obj && typeof obj === 'object')
				? Object.hasOwn(obj, value)
				: false;

			Object.keys(videoConstraints).forEach((constraintName) => {
				if (constraintName === 'optional') {
					return;
				}

				if (!hasOwn(capabilities, constraintName)) {
					if (process.env.NODE_ENV !== 'production' && params.get('verbose')) {
						console.log('constraint not supported: ', constraintName, videoConstraints[constraintName]);
					}

					// constraint not supported.
					delete videoConstraints[constraintName];
					return;
				}
				const constraint = videoConstraints[constraintName];
				const capability = capabilities[constraintName];

				if (
					hasOwn(constraint, 'min')
					&& hasOwn(capability, 'min')
					&& capability.min > constraint.min
				) {
					constraint.min = capability.min;
				}

				if (
					hasOwn(constraint, 'max')
					&& hasOwn(capability, 'max')
					&& capability.max < constraint.max
				) {
					constraint.max = capability.max;
				}
				if (
					hasOwn(constraint, 'ideal')
					&& hasOwn(capability, 'max')
					&& capability.max < constraint.ideal
				) {
					constraint.ideal = capability.max;
				}
			});
		} else if (is.desktop()) {
			// Desktop firefox does not support track.getCapabilities.
			// When facingMode is provided OverconstrainedError is thrown.
			delete videoConstraints.facingMode;
		}
		return videoConstraints;
	}

	async startupWebRTC() {
		const { webcam } = this.props;
		const { params } = webcam;
		const video = webcam.getVideo();

		// ask user for access to their camera
		webcam.helpers.detectVideoInputs(webcam.mediaDevices).then(async (cameraInfs) => {
			if (!cameraInfs.length) {
				webcam.dispatch('error', 'Camera not detected.');
			} else if (cameraInfs.length > 1 && params.get('cameraId') === undefined) {
				await webcam.switchCamera(params.get('camera'));
			}
			await this.checkCameraAccess(); // fill `webcamAccessGranted`
			let videoConstraints = this.getConstraits(cameraInfs);

			webcam.dispatch('videoConstraints', videoConstraints);

			webcam.mediaDevices.getUserMedia({
				audio: params.get('audio') || false,
				video: this.getConstraits(cameraInfs)
			}).then(async (stream) => {
				const track = stream.getVideoTracks()[0];
				// NOTE: side effects! Place detectVideoInputs on top
				const newCameraInfs = await webcam.helpers.detectVideoInputs(webcam.mediaDevices);
				if (!params.get('webcamAccessGranted')) {
					// if camera access was not granted before, it's granted now.
					await webcam.switchCamera(params.get('camera'));
				}
				if (!params.get('webcamAccessGranted') || is.iOS()) {
					params.set({ webcamAccessGranted: true });
					// mediaDevices.getUserMedia callback fires ONLY when camera access is granted.
					// detectVideoInputs will provide new list with labels.
					videoConstraints = this.getConstraits(newCameraInfs, track);
					webcam.dispatch('videoConstraints', videoConstraints);
					try {
						// NOTE! at this point we are able to apply contraints just for
						// 		 selected camera. It's impossible to change camera here.
						await track.applyConstraints(videoConstraints);
					} catch (err) {
						return webcam.dispatch('error', err);
					}
				}

				if (process.env.NODE_ENV !== 'production' && params.get('verbose')) {
					console.log('stream from getUserMedia ready.', video);
				}

				const run = () => {
					// got access, attach stream to video
					video.srcObject = stream; // NOTE: Polyfilled with adapter-js
					webcam.stream = stream; // TODO: if not working, put it in onLoadedMetadata
					params.set('lastCameraId', track.getSettings().deviceId);

					// mediaDevices.getUserMedia callback fires ONLY when camera access is granted.
					// detectVideoInputs will provide new list with labels.
					webcam.helpers.detectVideoInputs(webcam.mediaDevices, true);
				};

				if (params.get('use_ImageCapture')) {
					// Create image capture object and get camera capabilities
					const imageCapture = new ImageCapture(track);

					imageCapture.getPhotoCapabilities()
						.then((photoCapabilities) => {
							// NOTE: browser is clearing photoCapabilities. We need to copy that manually.
							const capabilitiesKeys = Object.keys(photoCapabilities);

							params.set({
								photoCapabilities: pick(photoCapabilities, capabilitiesKeys),
							});
							run();
						})
						.catch((err) => {
							console.error('error while ImageCapture.getPhotoCapabilities', err);
							run();
						});
				} else {
					run();
				}
			}).catch((err) => {
				if (process.env.NODE_ENV !== 'production' && params.get('verbose')) {
					console.log('getUserMedia failed', err);
				}
				webcam.dispatch('error', err);
			});
		});
	}

	handleLoadedMetadata = (e) => {
		const { webcam } = this.props;
		if (process.env.NODE_ENV !== 'production' && webcam.params.get('verbose')) {
			console.log('handleLoadedMetadata', e);
		}
		webcam.params.set({ load: true });
		const video = webcam.getVideo();
		if (video?.paused) {
			video.play();
		}
	};

	handleUserMediaVideoPlaying = (e) => {
		const video = e.target;
		const { webcam } = this.props;

		if (process.env.NODE_ENV !== 'production' && webcam.params.get('verbose')) {
			console.log('handleUserMediaVideoPlaying', e);
		}

		webcam.params.set({ live: true });
		webcam.flip();

		const dimensions = {
			width: video.videoWidth,
			height: video.videoHeight,
		};

		const prevDimensions = webcam.params.get('loadedVideoDimensions');

		webcam.params.set('loadedVideoDimensions', dimensions);

		const pixels = dimensions.width * dimensions.height;
		if (
			webcam.params.get('load')
			&& (
				!Number.isFinite(pixels)
				|| pixels <= 4
			)
		) {
			console.warn('Some weird thing happened. Camera never fully loaded. Reattaching.');
			webcam.reattach();
		}
	};

	handleVideoRef = (element) => {
		const { params } = this.props.webcam;
		const video = findDOMNode(element);

		if (params.get('cameraVideoElement') !== video) {
			params.set('cameraVideoElement', video);
		}

		if (video) {
			// iOS on iphone mini doesn't hide the "play button". This code might fix it
			video.setAttribute('autoplay', 'autoplay');

			// Never resolved react issue doesn't allow us to add defaultMuted with React
			// https://github.com/facebook/react/issues/6544
			video.defaultMuted = true;
		}
	};

	componentWillUnmount() {
		super.componentWillUnmount();
		this.resetCamera();
	}

	handleEmptied = () => {
		this.setTimeout(async () => {
			await this.resetCamera();
			this.setState({ load: false, live: false });
		}, 1000);
	};

	handleError = (err) => {
		console.error('webrtc component error:', err);
		this.resetCamera();
	};

	resetCamera = (cb = noop) => {
		const { webcam } = this.props;
		const video = webcam.getVideo();

		if (!video) return cb();

		const stream = video.srcObject;
		if (stream) {
			stream.getTracks().forEach((track) => {
				track.stop();
			});
		}
		video.srcObject = null;
		video.removeAttribute('src');
		// https://stackoverflow.com/questions/3258587/how-to-properly-unload-destroy-a-video-element
		video.load();
		delete webcam.stream;

		cb();
	};

	render() {
		return (
			<video
				ref={ this.handleVideoRef }
				className={ `${this.state.cssPrefix}__video` }
				autoPlay="autoplay"
				playsInline="playsinline" // THIS IS IMPORTANT FOR iOS11 !
				muted={ true }
				controls={ false }
				disableRemotePlayback={ true }
				// defaultMuted={ true }
				// onCanPlay={() => { console.log('video: canplay'); }}
				// onSeeked={() => { console.log('video: seeked'); }}
				// onSeeking={() => { console.log('video: seeking'); }}
				// onStalled={() => { console.log('video: stalled'); }}
				// onSuspend={() => { console.log('video: suspend'); }}
				// onWaiting={() => { console.log('video: onwaiting'); }}
				// onended={() => { console.log('video: onended'); }}
				onPlaying={ this.handleUserMediaVideoPlaying }
				onLoadedMetadata={ this.handleLoadedMetadata }

				onError={ this.resetCamera }
				onEmptied={ this.handleEmptied }
			>
			</video>
		);
	}
};
