/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import AgeVerificationContent from './components/AgeVerificationContent';
import help from 'models/help';

export default class AgeVerification extends BasePage {
  className = 'ts-AgeVerification';
  title = 'Age Verification by Trust Stamp';
  description =
    'Trust Stamp’s age verification solution is a secure, privacy-protecting, and accurate way to verify the age of your customers. Our solution is built on our proprietary AI-powered biometric identity platform, which is trusted by governments and businesses worldwide.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <Banner theme="age">
          <h1>Safer Connections Through Reliable Age Checks</h1>
          <p>
            Trust Stamp reliably automates the access of age-appropriate
            content, services, and products, online or onsite. Preserve your
            user experience while elevating user safety, fortifying platform
            security, and enhancing brand credibility.
          </p>
          <Link to={ ROUTES.CONTACT_US }>Get in Touch</Link>
        </Banner>

        <AgeVerificationContent />
      </div>
    );
  }
}
