/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import BasePage from 'pages/BasePage';
import Banner from 'components/Banner';
import Resources from 'components/Resources';
import VideoAndContent from 'components/VideoAndContent';
import Content from './components/Content';
import { VIDEO_CONTENT } from './constant';
import { ROUTES } from 'constants';
import help from 'models/help';

export default class Products extends BasePage {
  className = 'ts-Products';
  title = 'Secure Identity Tools';
  description = 'Trust Stamp\'s flexible product structure can establish, grow, and strengthen your systems for account access, fraud prevention, compliance, and more.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner */ }
        <Banner theme="globe">
          <h1>A complete identity solution...</h1>
          <span>OR</span>
          <h1>the technology that completes yours</h1>
        </Banner>

        { /* product listing */ }
        <Content />

        { /* video section */ }
        <VideoAndContent content={ VIDEO_CONTENT } colored={ true } />

        { /* resources section */ }
        <Resources formLayout={ true }>
          <article>
            <h2>Talk to a specialist</h2>
            <p>Get in touch with a team member today to learn more about Trust Stamp's solutions for security, privacy, and user accessibility.</p>
          </article>
          <aside>
            <Link className="ts-button--tertiary" to={ ROUTES.CONTACT_US }>Get in Touch Now</Link>
          </aside>
        </Resources>

      </div>
    );
  }
}
