import React from 'react';
import Box from 'components/Box';
import Dialog from 'components/Dialog';
import { asset } from 'helpers';
import { cacheParam } from 'sf/helpers';
import BasePage from 'pages/BasePage';
import Icon from 'sf/components/Icon';
import { Col, Row } from 'sf/components/Grid';
import { addEventListener, removeEventListener } from 'sf/helpers/domHelper';
import {
  ADVISORY_BOARD,
  OPERATIONS,
  QUALITY_ASSURANCE,
  DEVELOPMENT,
  MANAGEMENT_TEAM,
  COFOUNDERS,
  CORPORATE_BOARD_SHARED,
  CORPORATE_BOARD,
} from './constants';

export default class TeamMembersAi extends BasePage {
  className = 'ts-TeamMembersAi';
  title = 'Team';
  // eslint-disable-next-line max-len
  description = 'Meet our team of biometrics and artificial intelligence experts, engineers and entrepreneurs committed to creating secure societal and financial inclusion.';

  state = {
    activeTeamMemberId: null,
    activeTeams: null,
    inTransition: false,
    isDialogVisible: false,
  };

  componentDidUpdate(_, prevState) {
    if (!prevState.activeTeamMemberId && this.state.activeTeamMemberId) {
      addEventListener(window, 'keyup', this.handleKeyUp);
    }
    if (prevState.activeTeamMemberId && !this.state.activeTeamMemberId) {
      removeEventListener(window, 'keyup', this.handleKeyUp);
    }
  }

  handleKeyUp = ({ key }) => {
    if (key === 'ArrowLeft') {
      this.handlePreviousButtonClick();
    } else if (key === 'ArrowRight') {
      this.handleNextButtonClick();
    }
  };

  handleNextButtonClick = () => {
    const { activeTeamMemberId, activeTeams } = this.state;
    if (activeTeamMemberId === activeTeams.length - 1) return;
    this.setState(
      { activeTeamMemberId: activeTeamMemberId + 1 },
      this.handlePersonChange,
    );
  };

  handlePersonChange = () => {
    this.setState(
      {
        teamMemberHeight: this.personWrapper.offsetHeight,
        inTransition: true,
      },
      () => setTimeout(() => {
        this.setState({ inTransition: false });
      }, 200),
    );
  };

  handlePreviousButtonClick = () => {
    if (this.state.activeTeamMemberId === 0) return;
    this.setState(
      { activeTeamMemberId: this.state.activeTeamMemberId - 1 },
      this.handlePersonChange,
    );
  };


  handleTeamMemberBoxClick(event, { slug: teamMemberSlug }, teamMembers) {
    event.preventDefault();
    if (teamMembers === MANAGEMENT_TEAM) {
      this.setState({ activeTeams: MANAGEMENT_TEAM });
    }

    if (teamMembers === ADVISORY_BOARD) {
      this.setState({ activeTeams: ADVISORY_BOARD });
    }

    if (teamMembers === CORPORATE_BOARD_SHARED) {
      return null;
    }

    if (teamMembers === CORPORATE_BOARD) {
      this.setState({ activeTeams: CORPORATE_BOARD });
    }

    const activeTeamMemberId = teamMembers.findIndex(({ slug }) => slug === teamMemberSlug);
    this.setState({ activeTeamMemberId });
  }

  renderMainSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h1 className={ this.cn`__hero__title` }>
            Team <br /> Members
          </h1>
        </div>
      </section>
    );
  }

  renderTeamMemberPicture = ({ teamMember, itemModifier, textModifier, pictureModifier } = {}) => {
    const className = (prefix, modifier) => {
      return {
        [`${prefix}`]: true,
        [`${prefix}--${modifier}`]: modifier,
      };
    };

    return (
      <div className={ this.cn(className('__member-item', itemModifier)) }>
        <img
          alt={ `${teamMember.name} - portrait` }
          className={ this.cn(className('__member-portrait', pictureModifier)) }
          src={ cacheParam(asset`img/team/${teamMember.slug}.png`) }
        />
        <p className={ this.cn(className('__member-name', textModifier)) }>
          { teamMember.name }
        </p>
        <p className={ this.cn(className('__member-role', textModifier)) }>
          { teamMember.role }
        </p>
      </div>
    );
  };

  renderTeamMemberDetails = (teamMembers) => {
    const teamMember = teamMembers[this.state.activeTeamMemberId];
    return (
      <div
        className={ this.cn`__dialog-person` }
        style={ {
          height: this.state.teamMemberHeight,
        } }
      >
        <div
          className={ this.cn`__dialog-person-wrapper` }
          ref={ (wrapper) => { this.personWrapper = wrapper; } }
        >
          { this.renderTeamMemberPicture({
            teamMember,
            itemModifier: 'center',
            textModifier: 'big',
            pictureModifier: 'small',
          }) }

          <div
            className={ this.cn({
              '__dialog-description': true,
              '__dialog-description--in-transition': this.state.inTransition,
            }) }
          >
            { teamMember.description }
          </div>
        </div>
      </div>
    );
  };

  renderTeamMemberDialog = () => {
    const { activeTeamMemberId, activeTeams } = this.state;
    if (activeTeamMemberId === null) return;

    return (
      <Dialog
        className={ this.cn`__dialog` }
        size="xlarge"
        ref="dialog"
        visible={ true }
        onDismiss={ () => this.setState({ activeTeamMemberId: null }) }
      >
        <Row>
          <Col
            className={ this.cn`__dialog-navigation-button-wrapper` }
            initial={ 1 }
          >
            <button
              className={ this.cn`__dialog-navigation-button __dialog-navigation-button--previous
              ${activeTeamMemberId === 0
                ? '__dialog-navigation-button--disabled' : ''}` }
              onClick={ this.handlePreviousButtonClick }
            >
              <Icon set="fa" size={ 40 } type="angle-left" />
            </button>
          </Col>
          <Col initial={ 10 }>
            <Row>
              { this.renderTeamMemberDetails(activeTeams) }
            </Row>
          </Col>
          <Col
            className={ this.cn`__dialog-navigation-button-wrapper` }
            initial={ 1 }
          >
            <button
              className={ this.cn`__dialog-navigation-button __dialog-navigation-button--next
              ${activeTeamMemberId === activeTeams.length - 1
                ? '__dialog-navigation-button--disabled' : ''}` }
              onClick={ this.handleNextButtonClick }
            >
              <Icon set="fa" size={ 40 } type="angle-right" />
            </button>
          </Col>
        </Row>
      </Dialog>
    );
  };

  renderCofounderTile = (cofounder) => {
    return (
      <div key={ cofounder.slug } className={ this.cn`__main-member` }>

        { /* placeholder for anchor scroll */ }
        <a className={ this.cn`__anchor __test` } id={ cofounder.slug }>{ cofounder.name }</a>

        { this.renderTeamMemberPicture({ teamMember: cofounder, itemModifier: 'left' }) }

        <div className={ this.cn`__main-member-description` }>
          { cofounder.description }
        </div>
      </div>
    );
  };

  renderTeamMemberTile = (teamMember) => {
    return (
      <Box
        className={ this.rootcn`__item` }
        key={ teamMember.slug }
        fluid={ true }
        hover={ true }
        onClick={ (event) => this.handleTeamMemberBoxClick(event, teamMember) }
      >
        { this.renderTeamMemberPicture({
          teamMember,
          textModifier: 'small',
          pictureModifier: 'small',
        }) }
      </Box>
    );
  };

  mapTeamMembers(teamMembers) {
    if (teamMembers === CORPORATE_BOARD_SHARED) {
      return teamMembers.map((teamMember) => {
        return (
          <a
            href={ `#${teamMember.slug}` }
            key={ teamMember.slug }
            className={ this.cn`__anchor-link` }
          >
            <Box
              className={ this.rootcn`__item __mini` }
              key={ teamMember.slug }
              fluid={ true }
              hover={ true }
            >
              { this.renderTeamMemberPicture({
                teamMember,
                textModifier: 'small',
                pictureModifier: 'small',
              }) }
            </Box>
          </a>
        );
      });
    }

    return teamMembers.map((teamMember) => {
      return (
        <Box
          className={ this.rootcn`__item` }
          key={ teamMember.slug }
          fluid={ true }
          hover={ true }
          onClick={ (event) => this.handleTeamMemberBoxClick(event, teamMember, teamMembers) }
        >
          { teamMember.corporateBoard &&
            <a className={ this.cn`__anchor` } id={ teamMember.slug }>{ teamMember.name }</a>
          }
          { this.renderTeamMemberPicture({
            teamMember,
            textModifier: 'small',
            pictureModifier: 'small',
          }) }
        </Box>
      );
    });
  }

  // Management Team
  renderManagementTeam() {
    return (
      <section className={ this.rootcn`ts-container` }>
        <h2 className={ this.cn`__main-list-title` }>Management team</h2>
        <div className={ this.cn`__list` }>
          <Row center={ ['xsm'] }>
            <Col xsm={ 10 }>
              <div className={ this.cn`__row-small-margin` }>
                { this.mapTeamMembers(MANAGEMENT_TEAM) }
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  }

  renderAdvisoryBoardList() {
    return (
      <section className={ this.cn`__section-advisory-board` }>
        <h2 className={ this.cn`__main-list-title` }>Advisory Board</h2>
        <div className={ this.cn`__list` }>
          <Row center={ ['xsm'] }>
            <Col xsm={ 10 }>
              <div className={ this.cn`__row-small-margin` }>
                { this.mapTeamMembers(ADVISORY_BOARD) }
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  }

  renderCorporateBoardList() {
    return (
      <section className={ this.cn`__section-advisory-board` }>
        <h2 className={ this.cn`__main-list-title` }>Corporate Board</h2>
        <div className={ this.cn`__list __mini-conatiner` }>
          <Row center={ ['xsm'] }>
            <Col xsm={ 10 }>
              <div className={ this.cn`__row-small-margin` }>
                { this.mapTeamMembers(CORPORATE_BOARD_SHARED) }
                { this.mapTeamMembers(CORPORATE_BOARD) }
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  }

  // Cofounders
  renderCofounders() {
    return (
      <section className={ this.rootcn`ts-container` }>
        <div className={ this.cn`__main-list` }>
          <h2 className={ this.cn`__main-list-title` }>Cofounders</h2>
          <div className={ this.cn`__row-small-margin` }>
            { COFOUNDERS.map(this.renderCofounderTile) }
          </div>
        </div>
      </section>
    );
  }

  renderSingleAdvisorList = ({ title, members }) => [
    <h3 className={ this.cn`__advisor-title` } key={ `${title}-title` }>
      { title }
    </h3>,
    <ul className={ this.cn`__advisor-list` } key={ `${title}-members` }>
      { members.map(({ name }) => (
        <li
          key={ name }
        >
          { name }
        </li>
      )) }
    </ul>,
  ];

  renderTeamMembers() {
    return (
      <section className={ this.cn`__team` }>
        <div className={ this.cn`__team__container` }>
          <div className={ this.cn`__team__container--half` }>
            {
              [{
                title: 'Science, Engineering, R&D, Product',
                members: DEVELOPMENT,
              }].map(this.renderSingleAdvisorList)
            }
          </div>
          <div className={ this.cn`__team__container--half` }>
            {
              [{
                title: 'Operations, Sales and Support',
                members: OPERATIONS,
              }, {
                title: 'Quality Assurance',
                members: QUALITY_ASSURANCE,
              }].map(this.renderSingleAdvisorList)
            }
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderMainSection() }
        { this.renderCofounders() }
        { this.renderManagementTeam() }
        { this.renderTeamMembers() }
        { this.renderCorporateBoardList() }
        { /* { this.renderAdvisoryBoardList() } */ }
        { this.renderTeamMemberDialog() }
      </div>
    );
  }
}
