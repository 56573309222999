const allL10n = {};

/**
 * Use this function in every original (EN) translation file.
 *
 * @param  {Object} originalLines Translation lines
 * @return {Object}               Language object/dict
 */
export const registerLanguageFile = (originalLines) => {
  if (ENV === 'local') {
    Object.keys(originalLines).forEach((key) => {
      if (Object.hasOwn(allL10n, key)) {
        throw new Error(`l10n already includes ${key}`);
      }
    });
  }

  return Object.assign(allL10n, originalLines);
};

/**
 * Use this function to clear all registered languages.
 */
export const resetLanguageData = () => {
  Object.keys(allL10n).forEach((lineKey) => {
    delete allL10n[lineKey];
  });
};

/**
 * Returns translation line by given key of the currently used language.
 * When no line provided, then all translatios are returned as an object.
 *
 * @param  {string or null} line
 * @return {string or Object}
 */
export const getL10n = (line) => {
  return line
    ? allL10n[line]
    : allL10n;
};

/**
 * Download translation file that contains all registered translation files.
 * This function is meant to be used by people who implements SDK.
 */
export const exportTranslationFile = (fileName = 'truststamp-sdk-translations') => {
  require('sf/helpers').getFileFromBlob({
    text: JSON.stringify(allL10n, null, 2),
    extension: 'json',
    name: fileName,
    type: 'application/json',
  });
};

/**
 * Option to update any translation line/file dynamically.
 *
 * @param  {Object} linesToUpdate Language object/dict
 */
export const updateTranslations = (linesToUpdate) => {
  if (ENV === 'local') {
    // just for local development.
    // When updated line doesn't exist in the original file, throw an error.

    Object.keys(linesToUpdate).forEach((lineKey) => {
      if (!Object.hasOwn(allL10n, lineKey)) {
        // eslint-disable-next-line no-console
        console.error(`Original language file does not contain "${lineKey}"`);
      }
    });
  }

  Object.assign(allL10n, linesToUpdate);
};
