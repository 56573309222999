/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import CorporateKycContent from './components/CorporateKycContent';
import help from 'models/help';

export default class CorporateKyc extends BasePage {
  className = 'ts-CorporateKyc';
  title = 'Age Verification by Trust Stamp';
  description =
    'Trust Stamp’s age verification solution is a secure, privacy-protecting, and accurate way to verify the age of your customers. Our solution is built on our proprietary AI-powered biometric identity platform, which is trusted by governments and businesses worldwide.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <Banner theme="kyc">
          <h1>
            Elevate KYC & Customer Due Diligence with Automated Identity
            Verification
          </h1>
          <p>
            Enhance your Know Your Customer (KYC) and Customer Due Diligence
            (CDD) processes using Trust Stamp's cutting-edge AI and computer
            vision technology. Our solutions are designed to provide seamless
            identity verification at scale, ensuring that your onboarding
            processes are not just compliant but also a cornerstone of your
            defense against identity fraud.
          </p>
          <Link to={ ROUTES.CONTACT_US }>Get in Touch</Link>
        </Banner>

        <CorporateKycContent />
      </div>
    );
  }
}
