import React from 'react';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

import BaseComponent from 'components/BaseComponent';

const ALL_REGIONS = ['america', 'europe', 'asia', 'oceania', 'africa', 'north-america',
  'south-america', 'central-america', 'carribean', 'eu-union', 'ex-ussr', 'ex-yugos',
  'baltic', 'middle-east', 'north-africa'
];

/**
 * This component anables the International phone number.
 * This component uses react-phone-input-2.
 *
 * `value` will be the parsed phone number in E.164 format. +<country_code><subscriber_number>
 * Example: "+14155552671".
 *
 * Default mask is "... ... ... ... .."
 */
export default class PhoneNumberInput extends BaseComponent {
  className = 'ts-PhoneNumberInput';

  static propTypes = {
    disabled: PropTypes.bool,
    displayName: PropTypes.string,
    error: PropTypes.string,
    isValid: PropTypes.oneOf([true, false, null]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.array,
    initialValue: PropTypes.string,
    stateLink: PropTypes.array,
    defaultCountry: PropTypes.string.isRequired, // Country code
    placeholderAlwaysVisible: PropTypes.bool,
    placeholderInitiallyVisible: PropTypes.bool,
    hint: PropTypes.node,
    regions: PropTypes.array,
    countryCodeEditable: PropTypes.bool,
    neededCountries: PropTypes.array, // country codes to be included e.g: ['us','fr','rw']
    masks: PropTypes.object, // e.g: {fr: '(...) ..-..-..', at: '(....) ...-....'}
    preferredCountries: PropTypes.array, // country codes to be at the top
  };

  static defaultProps = {
    displayName: '',
    error: '',
    isValidatedByProp: false,
    isValid: null,
    onBlur: noop,
    onChange: noop,
    onFocus: noop,
    placeholderAlwaysVisible: true,
    placeholderInitiallyVisible: false,
    regions: ALL_REGIONS,
    countryCodeEditable: true,
    neededCountries: [],
    preferredCountries: []
  };

  constructor(props) {
    super(props);
    this.state = {
      isValid: props.isValid,
      placeholderVisible: props.placeholderInitiallyVisible && props.countryCodeEditable,
      value: props.initialValue || ''
    };
  }

  get value() {
    return this.state.value;
  }

  componentDidUpdate() {
    this.displayErrorIfNeeded();
  }

  displayErrorIfNeeded() {
    if (!this.props.isValidatedByProp) return;
    this.setValid(!this.props.error, this.props.error);
  }

  setValid = (status, invalidMessage = '') => { // public
    this.setState({
      isValid: status,
      invalidMessage: status ? false : invalidMessage,
    });
  };

  focus = () => { // public
    this.refs.input.numberInputRef.focus();
  };

  handleBlur = (e) => {
    this.setState({ placeholderVisible: !!this.props.placeholderAlwaysVisible });
    this.props.onBlur(e);
  };

  handleChange = (newValue) => {
    const [context, stateField] = this.props.stateLink || [];

    const setStateCallback = () => {
      this.setValid(null);
      this.props.onChange(newValue);
    };

    if (context) {
      if (newValue === context.state[stateField]) {
        return;
      }
      context.setState(() => ({
        [stateField]: newValue,
      }), setStateCallback);
    } else {
      this.setState(() => ({ value: newValue }), setStateCallback);
    }
  };

  handleFocus = (e) => {
    this.setState({ placeholderVisible: true });
    this.props.onFocus(e);
  };

  isRegionValid = () => {
    return this.props.regions.every((region) => ALL_REGIONS.includes(region));
  }

  renderLabel() {
    if (!this.props.displayName) return null;

    return (
      <label
        id={ `${this.className}-${this.componentId}-label` }
        htmlFor={ `${this.className}-${this.componentId}-input` }
        className={ this.cn`__label` }
      >
        { this.props.displayName }
      </label>
    );
  }

  renderHint() {
    if (this.state.isValid === false && this.state.invalidMessage) {
      return (
        <div className={ this.cn`__hint __hint--error` }>
          { this.state.invalidMessage }
        </div>
      );
    }

    return this.props.hint
      ? <div className={ this.cn`__hint` }>{ this.props.hint }</div>
      : null;
  }

  render() {
    const phoneInputClassNames = {
      '__field': true,
      '__field--success': this.state.isValid,
      '__field--danger': this.state.isValid === false,
    };
    const placeholder = this.state.placeholderVisible ? this.props.placeholder : '';
    const regions = this.isRegionValid ? this.props.regions : ALL_REGIONS;
    const [context, stateField] = this.props.stateLink || [];
    const value = (context ? context.state[stateField] : this.state.value) || '';
    const propsToOmit = ['isValid'];

    return (
      <div
        { ...this.pickProps() }
        className={ this.rootcn`` }
        title={ this.state.invalidMessage || this.props.title }
      >
        { this.renderLabel() }
        <PhoneInput
          id={ `${this.className}-${this.componentId}-input` }
          aria-labelledby={ `${this.className}-${this.componentId}-label` }
          { ...omit(this.props, propsToOmit) }
          className={ this.cn(phoneInputClassNames) }
          placeholder={ placeholder }
          country={ this.props.defaultCountry }
          countryCodeEditable={ this.props.countryCodeEditable }
          value={ value }
          regions={ regions }
          onChange={ this.handleChange }
          onFocus={ this.handleFocus }
          onBlur={ this.handleBlur }
          onlyCountries={ this.props.neededCountries }
          preferredCountries={ this.props.preferredCountries }
          disabled={ this.props.disabled }
          masks={ this.props.masks }
          ref="input"
          tabIndex={ this.props.disabled ? '-1' : this.props.tabIndex }
          inputProps={ {
            required: this.props.required,
          } }
        />
        { this.renderHint() }
      </div>
    );
  }
}
