import React from 'react';
import BasePage from 'pages/BasePage';
import En from 'components/Terms/En';
import Fr from 'components/Terms/Fr';
import Es from 'components/Terms/Es';

const languageComponents = {
  en: <En />,
  fr: <Fr />,
  es: <Es />
};

export default class SharedTermsAndConditions extends BasePage {
  className = 'ts-SharedTermsAndConditions';
  title = 'Terms And Conditions';
  description =
    'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  componentDidMount() {
    // Add the event listener to the 'message' event of the window object
    this.addEventListener(window, 'message', (event) => {
      // send a handshake to parent window to confirm that the iframe can be scrolled
      if (event.data === 'handshake') {
        event.source.postMessage('handshake', event.origin);
      }

      if (event.data === 'scroll') {
        // Scroll to the bottom of the parent window with smooth behavior when the 'scroll' message is received
        const targetElement = this.refs.contentBottom;
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  handleScroll = ({ target }) => {
    // Send bottomReached signal to iframe parent to trigger consent has been read.
    if ((target.scrollHeight - target.scrollTop) <= target.clientHeight) {
      window.parent.postMessage('bottomReached', '*');
    }
  }

  render() {
    const { query } = this.props.location;
    const { lang } = query;

    const selectedComponent = languageComponents[lang] || <En />;

    return (
      <div className={ this.rootcn() }>
        <div ref="termsContainer" onScroll={ this.handleScroll } className={ this.cn`__container` }>
          { selectedComponent }
          <div ref="contentBottom" />
        </div>
      </div>
    );
  }
}
