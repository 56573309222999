import React from 'react';
import { COPY_BIOMETRIC_CHECKIN } from 'apps/ai/pages/CorporateIndustries/copy';
import BasePage from 'pages/BasePage';
import HeroComponent from '../CorporateIndustries/CorporateComponents/CorporateIndustriesHero';
// eslint-disable-next-line max-len
import CorporateSectionComponent from '../CorporateIndustries/CorporateComponents/CorporateSectionComponent';

export default class BiometricEventCheckin extends BasePage {
  className = 'ts-BiometricEventCheckin';
  title = 'BiometricEventCheckin';

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <HeroComponent
          title="IAFCI Biometric Check-In"
          extraClass="biometricCheckin"
        />
        <CorporateSectionComponent data={ COPY_BIOMETRIC_CHECKIN } />
      </div>
    );
  }
}
