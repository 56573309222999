import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import Card from 'sf/components/Card';
import HeroComponent from '../CorporateIndustries/CorporateComponents/CorporateIndustriesHero';
import { PROCESS_2 } from './constant';

const ContentSection = (props) => (
  <section className="ts-Humanitarian__content">
    { props.children }
  </section>
);

export default class Humanitarian extends BasePage {
  className = 'ts-Humanitarian';
  title = 'Humanitarian';
  // eslint-disable-next-line max-len
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  renderCard = (copy) => (
    <Card
      className={ this.cn`__card` }
      key={ copy.title }
    >
      <img
        alt=""
        className={ this.cn`__card-image` }
        src={ `/ai/${copy.slug}` }
      />
      <h4 className={ this.cn`__card-title` }>{ copy.title }</h4>
      <p className={ this.cn`__card-copy` }>{ copy.description }</p>
    </Card>
  );

  renderCardSection = (src) => (
    <section className={ this.cn`__cards` }>
      <div className={ this.cn`__container` }>
        <h2 className={ this.cn`__title` }> Process </h2>
        <div className={ this.cn`__responsive-container` }>
          { src.map(this.renderCard) }
        </div>
      </div>
    </section>
  );

  render() {
    return (
      <div className={ this.rootcn() }>
        <HeroComponent title="Humanitarian" extraClass="humanitarian" />
        { /* eslint-disable max-len */ }
        { /* <ContentSection>
          <div className={ this.cn`__content-container` }>
            <h2 className={ this.cn`__title __content-title` }>
              Providing Identity Authentication for the Global Population of People without Access to Sufficient Means of Identification
            </h2>
            <p>Trust Stamp’s technology is used to provide secure, non-PII authentication in a Mastercard Humanitarian and Development initiative announced in 2019.</p>
            <p>Mastercard’s Senior Vice President for Humanitarian and Development programs, Shashi Raghunandan, said</p>
            <p className={ this.cn`__content-italicized` }>“Together, we can all help create an environment of trust where individuals without national IDs or verified documentation can begin a process of creating a more persistent, more private, and more secure digital identity that will help them re-build their lives and improve access to basic services.”</p>
            <Link
              className={ this.cn`__content__button` }
              href={ 'https://www.prnewswire.com/news-releases/trust-stamp-receives-investment-from-mastercard-300995877.html' }
              target="_blank"
              rel="noopener noreferrer"
            >
              Read the Press Release
            </Link>
          </div>
        </ContentSection>
        { this.renderCardSection(PROCESS_1) } */ }
        <ContentSection>
          <div className={ this.cn`__content-container` }>
            <h2 className={ this.cn`__title __content-title` }>
              { /* eslint-disable max-len */ }
              Identifying victims of human trafficking with international Attorneys General
            </h2>
            <p>In collaboration with CWAG (Conference of Western Attorneys General) and the Alliance Partnership, Trust Stamp has built a consortium Identity Lake shared between the Attorneys General of New Mexico and Guanajuato, Mexico. Using Trust Stamp’s Facial Matching and Hashing Technology, the two Attorneys General offices can share hashes related to human trafficking persons of interest. The Identity Lake is a proven method of cross jurisdictional, compliant data sharing.</p>
            <Link
              className={ this.cn`__content__button` }
              href="https://www.biometricupdate.com/201808/truststamp-biometric-human-trafficking-detection-system-to-be-piloted-in-u-s-and-mexico"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read the Press Release
            </Link>
          </div>
        </ContentSection>
        { this.renderCardSection(PROCESS_2) }
      </div>
    );
  }
}
