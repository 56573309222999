/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { asset } from 'helpers';

export default class Vital4 extends BasePage {
  className = 'ts-Vital4';
  title = 'Vital4';
  // eslint-disable-next-line max-len
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section className={ this.cn`__hero __hero--technology` }>
          <div className={ this.cn`__hero-color` } />
          <div className={ this.cn`__hero__container` }>
            <h1 className={ this.cn`__hero__title` }>Press Releases</h1>
          </div>
        </section>

        <div className={ this.cn`__section__container` }>
          <div className={ this.cn`__left` }>
            <h2 className={ this.cn`__section__title` }> Trust Stamp Partners with Vital4 to take KYC / AML to the Next Level </h2>
            <h3 className={ this.cn`__section__subtitle` }> Atlanta based A.I. Company to deliver a faster, more secure, and cost-effective solution for KYC / AML compliance</h3>
          </div>
          <div className={ this.cn`__right` }>
            <img
              alt=""
              src={ asset`ai/corporate-industries/TS-Vital4-logos.png` }
              className={ this.cn`__section__picture` }
            />
          </div>
          <div className={ this.cn`__section__wrapper` }>
            <p className={ this.cn`__section__paragraph` }>
              <strong>Atlanta, GA, July 24, 2019:</strong> Trust Stamp, a provider of proprietary artificial intelligence-powered identity services headquartered in Atlanta, GA has entered into a partnership with Vital4 to provide an end-to-end enhanced KYC / AML solution to its diverse global client base through Trust Stamp’s employee and customer onboarding solutions. “Trust Stamp OnBoard” is a highly robust Know Your Customer / Anti-Money Laundering (KYC / AML) screening solution that is powered by Vital4’s data search technology. Vital4 utilizes best of breed cloud technology driven by Artificial Intelligence and Machine Learning to identify individuals and entities who may be a risk to financial institutions.
            </p>
            <p className={ this.cn`__section__paragraph` }>
              The important advantages derived from this partnership are significant for Trust Stamp’s customers as they will now have the ability to gain information and insight into global watch and sanctions lists, global negative media, and politically exposed persons (PEP) profiles with up-to-date data captured live and direct from the source, providing results instantaneously through this newly established API integration. Trust Stamp’s customers will also be able to perform ongoing enhanced due diligence on customers and entities with VITAL4’s Continuous Monitoring Solution.
            </p>
            <p className={ this.cn`__section__paragraph` }>
              “Over the last several years we have repeatedly been approached to provide identity verification for KYC / AML compliance and have grown to recognize the scale and breadth of the opportunity” says Alex Valdes, Trust Stamp’s CFO & EVP. Alex went on to say, “Vital4 has impressed us as a dynamic and highly sophisticated, globally accessible provider of due diligence services and we are confident that our combined services will create a strong and unique offering.”
            </p>
            <p className={ this.cn`__section__paragraph` }>
              The integration between these two technology companies is the latest innovation in KYC / AML compliance to track criminal and terrorist financing networks with embedded ID verification and the most robust data platform in the industry.  This next generation of ID verification and expansive KYC / AML screening and monitoring is a clear integration of two powerful technologies with enhanced artificial intelligence and machine learning, aiding customers in the reduction of overall risk exposure in the fight against money laundering and terrorist financing. The combination of hashed biometrics and cutting edge data services, offers significant functional benefits over physical-based identity systems.
            </p>
            <p className={ this.cn`__section__paragraph` }>
              <strong> About Trust Stamp: </strong><br />
              Trust Stamp applies advanced cryptographic techniques and Artificial Intelligence-powered presentation-attack detection tools to biometric and other identity data to create a proprietary non-PII IT<sup>2</sup>. The IT<sup>2</sup> is unique to the subject and provides a tokenized identity that cannot be reverse-engineered. The IT<sup>2</sup> constitutes "anonymized data" and is immune to data breaches. Hashes can be compared to Trust Stamp’s proprietary Identity Lake™, video-streams, photo-ID, and data-mined images to establish a subject's identity and can be augmented with pivot points to other relevant data to enable zero-knowledge-proofs. Trust Stamp technology is used in multiple industries, including banking, FinTech, KYC / AML compliance, real estate, travel/hospitality, and law enforcement.
            </p>
            <p className={ this.cn`__section__paragraph` }>
              <strong> About VITAL4: </strong><br />
              VITAL4 provides global screening solutions to help organizations maintain regulatory compliance and mitigate third-party risk. As a cloud software platform made fast and powerful by proprietary machine learning technology, VITAL4 was developed to bring the agility of SaaS services to enterprise compliance regimes and eliminate slow update cycles, shallow source pools and the human-error risks of manual legacy processes. VITAL4 was built with four fundamental concepts in mind: compliance, accuracy, global insight and value.
            </p>
            <p className={ this.cn`__section__paragraph` }>
              <strong> Related Links </strong><br />
              <a
                href="https://vital4.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://vital4.net
              </a><br />
              <a
                href="https://atdc.org/portfolio-overview/atdc-signature/trust-stamp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://atdc.org/portfolio-overview/atdc-signature/trust-stamp/
              </a>
            </p>
            <p className={ this.cn`__section__paragraph` }>
              <strong>VITAL4 Contact:</strong> <br />
              Amy Barbieri, Chief Revenue Officer &amp; VP of Marketing <br />
              <a href="tel:404-771-1893">404-771-1893</a> <br />
              <a href="mailto:info@VITAL4.net">info@VITAL4.net</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
