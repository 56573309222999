/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { asset } from 'helpers';
import { downloadFileFromUrl } from 'sf/helpers';
import WhitepaperForm from './WhitepaperForm';

export default class Whitepaper extends BasePage {
  className = 'ts-Whitepaper';
  title = 'Whitepaper';
  // eslint-disable-next-line max-len
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';

  static defaultProps = {
    thankYou: false,
  }

  state = {
    isMessageSent: false,
  };

  // form submisison success
  performSuccessRedirect = () => {
    this.setState({
      isMessageSent: true,
    });

    // download a PDF on successful form submission
    downloadFileFromUrl(asset`ai/Privacy-Protecting-Power-of-IT2-September-2020-6.pdf`);
  };

  // Banner
  renderBannerSection() {
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn`__hero-color` } />
        <div className={ this.cn`__hero__container` }>
          <h1 className={ this.cn`__hero__title` }>
            Building Biometric Solutions on Privacy and Data Security
          </h1>
        </div>
      </section>
    );
  }

  // Content
  renderContent() {
    return (
      <article>
        <p>The world of digital identity faces a dilemma: biometric technology has swept the market for secure, low-friction authentication, but its increasing popularity has created a lucrative target to expose weaknesses in data security, compromise sensitive biometric data and ultimately put people at risk.</p>
        <img src={ asset`ai/privacy-paper.png` } alt="Privacy paper" />
        <p>Coupled with privacy concerns and legislative scrutiny, the implementation of biometric solutions presents a decisive moment for consumer trust, organisational security, and regulatory compliance.</p>
        <p>Read how Trust Stamp’s tokenisation technology maximises the privacy and utility of personal data to enhance risk management and data protection measures while providing a low-friction customer authentication experience.
        </p>
      </article>
    );
  }

  // form section
  renderFormSection() {
    if (this.state.isMessageSent || this.props.thankYou) {
      return (
        <div className={ this.cn`__confirmation` }>
          <p>
            Thank you for downloading the free whitepaper.
          </p>
        </div>
      );
    }

    return (
      // form
      <WhitepaperForm onDone={ this.performSuccessRedirect } />
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>

        { /* banner section */ }
        { this.renderBannerSection() }

        <div className={ this.cn`ts-container` }>
          <section className={ this.cn`__content-area` }>

            { /* content section */ }
            { this.renderContent() }

            <aside>

              { /* Form section */ }
              { this.renderFormSection() }

            </aside>
          </section>
        </div>
      </div>
    );
  }
}
