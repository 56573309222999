import React, { Fragment } from 'react';

/* eslint-disable max-len */
export const MANAGEMENT_TEAM = [
  {
    description: (
      <Fragment>
        <p>
          Prior to joining Trust Stamp as CTO, Scott served for 9 years in the
          Program Management Office with Google. This role was very
          entrepreneurial in nature as he was tasked with helping oversee the
          creation and development of a global PMO team spread across multiple
          data centers across the US and Europe, essentially acting as a startup
          intrapreneur. Prior to Google, Scott served for 10 years in a number
          of startup companies in Atlanta, Austin and Silicon Valley in software
          programming, management, and configuration management roles.
        </p>
      </Fragment>
    ),
    name: 'Scott Francis',
    slug: 'scott-francis',
    role: 'CTO'
  },
  {
    description: (
      <Fragment>
        <p>
          Bill is a well-respected figure within the United Kingdom property
          market, having been involved in real estate for over fifty years.
          During this time he has been Managing Director of Royal Life Estates
          South with a chain of two hundred and fifty offices.
        </p>
        <p>
          He successfully exited Cornerstone Estate Agencies (three hundred and
          forty-seven offices), when it was purchased from Abbey National plc.,
          and subsequently joined Hamptons as International Development Director
          with specific responsibility for business generated in the markets of
          Hong Kong, Singapore and Malaysia.
        </p>
        <p>
          In 2003 he became the Chief Operating Officer of The Ombudsman for
          Estate Agents for the UK and in 2007 became Chairman a post he held
          until the end of 2015, which covers approximately 90 percent of all
          residential agents in the UK.
        </p>
      </Fragment>
    ),
    name: 'William ("Bill") McClintock',
    slug: 'bill-mcclintock',
    role: 'Board Chairman',
    corporateBoard: true
  },
  {
    description: (
      <Fragment>
        <p>
          Before graduating college, Alex founded and operated four separate
          companies, using the income from these businesses to pay his way
          through college. During his undergraduate, Alex spent 15 months
          studying abroad in Mexico, where he launched an innovative
          microfinance lending system in partnership with the Yucatan State
          Department of Economic Development.
        </p>
        <p>
          After successfully exiting each of the businesses, Alex returned to
          the US to complete his degree in accounting from The University of
          Georgia and became dual-certified with the CPA and CMA designations.
        </p>
        <p>
          Alex worked as a strategy consultant before joining Trust Stamp on the
          founding advisory team. After six months of advising, Alex joined the
          company full-time as the Chief Financial Officer, Executive Vice
          President, and Board Secretary.
        </p>
      </Fragment>
    ),
    name: 'Alex Valdes',
    slug: 'alex-valdes',
    role: 'CFO, EVP'
  },
  {
    description: (
      <Fragment>
        <p>
          Norman holds a PhD in Machine Learning and Information Fusion from
          IDIAP research institute, École Polytechnique Fédérale de Lausanne
          (EPFL), Switzerland. He is passionate about machine learning with
          applications to biometric person recognition, healthcare, forensics,
          financial forecasting, and other practical data intensive areas, where
          he published more than 100 peer-reviewed publications, including 5
          best paper awards (AVBPA’05, ICB’09, HSI 2010, ICPR 2010 and Pattern
          Recognition Journal 2006).
        </p>
      </Fragment>
    ),
    name: 'Dr. Norman Poh',
    slug: 'norman-poh',
    role: 'Chief Science Officer'
  },
  {
    description: (
      <Fragment>
        <p>
          Kinny Chan is an experienced leader with a passion for explaining
          complex challenges in simple and understandable terms. His expertise
          lies at the intersection between technology, business and the law,
          with over 10 years of experience in the enterprise sales of complex
          technology solutions.
        </p>
        <p>
          His expertise in data analytics and technology-assisted review has
          established his reputation as a sought-after expert. Kinny has
          extensive leadership experience built on a successful career of
          increasingly significant positions in the legal and technology
          industries. Having played a central role in establishing the
          prominence of companies he has served in the past, Kinny joined Trust
          Stamp as a key addition to guide their successful growth in 2020.
        </p>
      </Fragment>
    ),
    name: 'Kinny Chan',
    slug: 'kinny-chan',
    role: 'Chief Commercial Officer'
  },
  {
    description: (
      <Fragment>
        <p>
          Josh joins Trust Stamp having spent over 20 years in private equity,
          venture capital, and non-profit management. He serves on the board of
          directors of several charitable and educational organizations and is
          passionate about the future of education delivery channels. He has
          applied entrepreneurial models of operation to several US domestic and
          international non-profit organizations, transforming them into
          effective leaders in their respective spaces. Josh’s M&A transactional
          expertise is centered around financial services and technology.
        </p>
      </Fragment>
    ),
    name: 'Josh Allen',
    slug: 'josh-allen',
    role: 'EVP, Mergers & Acquisitions',
    corporateBoard: true
  },
  {
    description: (
      <Fragment>
        <p>
          John joined Trust Stamp from the United States Marshals Service where
          he served as a Senior Inspector/Chief Inspector-Financial Surveillance
          Unit as the culmination of a 34-year career in law enforcement. John’s
          experience encompasses the private sector with many years of working
          with investigators from Fortune 500 companies. John’s expertise
          includes fraud detection, digital currency and cyber investigations
          and operational risks and controls. John is actively involved in
          industry associations including IAFCI, ASIS and HTCIA.
        </p>
      </Fragment>
    ),
    name: 'John Bridge',
    slug: 'john-bridge',
    role: 'President of GovTech'
  },
  {
    description: (
      <Fragment>
        <p>
          Sandeep Virk has over 15 years of experience in the financial services
          industry, focusing on digital optimization, credit/fraud and
          enterprise risk management. His expertise helped his previous
          employer, Synchrony Financial, to develop several new platforms
          leveraging cloud-based services to provide real-time fraud & anomaly
          detection and automated end-to-end analytics, reporting, and
          monitoring.
        </p>
        <p>
          He has led initiatives which designed and developed API/micro services
          using the traditional tech stack as well as big data technologies, and
          led incubation work to deliver the next generation of digital
          authentication tools. Also, while working with Synchrony, he provided
          oversight to create/develop enterprise-wide triggers/limits to act as
          early warning indicators for risk appetite breaches, which encompass
          risk identification, business process analysis, risk and control gap
          analysis, KRIs, KPIs, RCSA and impact analysis across various risk
          pillars. Sandeep also led the development of a credit line increase
          process which supported $600MM worth of transactions and over 500
          thousand annual consumer/commercial credit line increase transactions.
          He is also Six Sigma Black Belt certified.
        </p>
      </Fragment>
    ),
    name: 'Sandeep Virk',
    slug: 'sandeep-virk',
    role: 'EVP'
  },
  {
    description: (
      <Fragment>
        <p>
          Daryl worked for GCHQ, the UK’s intelligence, cyber and security
          agency for 34 years, most recently as the Chief of Research and
          Innovation and the Deputy Chief Scientific Advisor for National
          Security.
        </p>
        <p>
          As an expert in cryptography and cyber security, Daryl specialised in
          cryptologic research, analysis and design, including applications and
          engineering for very large-scale infrastructure. In recent years, he
          was responsible for steering GCHQ’s extensive programme of research
          and innovation.
        </p>
        <p>
          Daryl retired from Government service in 2019 and is now pursuing his
          second career in the private sector, advising a range of clients on
          security protocols and their implementation.
        </p>
      </Fragment>
    ),
    name: 'Daryl Burns',
    slug: 'daryl-burns',
    role: 'Executive Advisor Cryptography & Security'
  },
  {
    description: (
      <Fragment>
        <p>
          Niel worked for GCHQ, the UK's intelligence, cyber and security
          agency, for 42 years, most recently as the Director General for
          Technology – the most senior cyber technologist in UK Government.
        </p>
        <p>
          At the heart of British intelligence, he was responsible for science
          and technology, from blue-sky research and innovation through system
          development, to operational support and management. He was at the
          forefront of GCHQ’s success in developing and deploying data analysis
          to the frontline of the intelligence and cyber security missions. Niel
          retired from Government service at the end of 2018 and is now pursuing
          his second career in the private sector, advising on cyber, data
          exploitation and innovation.
        </p>
      </Fragment>
    ),
    name: 'Dr. Niel Kempson',
    slug: 'niel-kempson',
    role: 'Executive Advisor, Technical Capability'
  },
  {
    description: (
      <Fragment>
        <p>
          Mike has been working in the IT and software industry for over 8
          years, specializing in all things QA. He has worn several hats for a
          variety of lines of business, from banking, insurance and finance
          international entities, from being a QA test analyst, to an Automation
          Engineer. Prior to joining the company, he served as a Test Manager
          for a mulinational hospitality firm, providing guidance and leadership
          to the local team.
        </p>
        <p>
          He is a holder of a Bachelors Degree in Computer Science, is a family
          man, and is known to strum a mean power chord every now and then.
        </p>
      </Fragment>
    ),
    name: 'Mike Cepe',
    slug: 'mike-cepe',
    role: 'VP Quality Assurance'
  },
  {
    description: (
      <Fragment>
        <p>
          Jonathan Patscheider has a global and entrepreneurial mindset,
          combined with a creative flair and a strategic perspective on the
          interplay between technology and business, and how to build strategic
          partnerships and industry relations. Jonathan Patscheider has worked
          as a Special Advisor in the Ministry of Foreign Affairs’ department
          for investment promotion, Invest in Denmark. As a Special Advisor
          Jonathan has been instrumental in attracting, maintaining, and
          developing foreign investments in Denmark and providing confidential
          one-stop services to foreign companies looking to set up a business in
          Denmark in the areas of AI, cybersecurity, and quantum technology.
          Jonathan received a Master of Science in Business Analytics from the
          Technical University of Denmark, where he specialized in fairness in
          machine learning for healthcare. His research on fairness was
          presented at a talk for the Danish Society of Engineers and has led to
          current collaborations with academics at MIT.
        </p>
      </Fragment>
    ),
    name: 'Jonathan Patscheider',
    slug: 'jonathan-patscheider',
    role: 'Vice President'
  }
];

export const COFOUNDERS = [
  {
    description: (
      <Fragment>
        <p>
          With over 20 years’ experience in founding, operational and advisory
          capacities, Gareth provides Trust Stamp with technical, managerial,
          and visionary skills, as well as legal expertise. Gareth has
          successfully conceptualized, implemented, scaled, and exited multiple
          businesses including a cloud storage enterprise which was sold, and an
          online educational platform which was acquired by a non-profit
          educational entity. Immediately prior to Trust Stamp, Gareth served as
          full-time CEO of Edevate LLC, and President of Pontifex University as
          well as part-time Chancellor of Holy Spirit. Gareth now serves as
          unpaid President of Pontifex University and Holy Spirit College which
          are managed by professional teams. A British lawyer by training,
          Gareth holds a U.S. LLM in International Taxation & Financial Service
          Regulation.
        </p>
      </Fragment>
    ),
    name: 'Gareth Genner',
    slug: 'gareth-genner',
    role: 'CEO'
  },
  {
    description: (
      <Fragment>
        <p>
          An economist by education, Andrew began his career in financial
          services sales and marketing. Although Trust Stamp is Andrew’s first
          start-up, he has immersed himself in the lean-start-up environment by
          completing incubator programs through Founder’s Space in San
          Francisco, QC FinTech in Charlotte, Plug and Play in both Silicon
          Valley and the United Arab Emirates and NAR REach<sup>&reg;</sup> in
          Chicago. Each of these programs has provided a unique perspective and
          honed a distinct set of startup skills. Prior to joining Trust Stamp,
          Andrew worked at Ashford Advisers, a financial services company, where
          he worked as a Marketing Coordinator until joining the Company. As
          President, Andrew oversees business development and operations and
          acts as Chief Product Evangelist.
        </p>
      </Fragment>
    ),
    name: 'Andrew Gowasack',
    slug: 'andrew-gowasack',
    role: 'President'
  }
];

export const QUALITY_ASSURANCE = [
  { name: 'Melchor R. Casipit Jr. – QA Engineer' },
  { name: 'Sovan Ghoshal – QA Engineer' },
  { name: 'Jeydee Lumbis – QA Engineer' },
  { name: 'Stephanie Magpantay – QA Engineer' },
  { name: 'Aaron Torres –  Mid-Level QA Engineer' },
  { name: 'Carmela Solomon - QA Engineer' },
  { name: 'Neil Acita - QA Engineer' }
];

export const DEVELOPMENT = [
  { name: 'Prof. Reuben Farrugia - Senior Vice President of Research and Development' },
  { name: 'Dr. Leanne Attard – Senior Vice President of Research and Development' },
  { name: 'Dr. David Grima – Director of Product Innovation' },
  { name: 'Omid Sarei – Mid Technical Sales Engineer' },
  { name: 'Alexander Kononenko – AI Engineer' },
  { name: 'Cezary Nowak – AI Engineer' },
  { name: 'Jacek Szpot – AI Engineer' },
  { name: 'Piotr Klibert – AI Engineer' },
  { name: 'Sergey Kospanov – AI Engineer' },
  { name: 'Anthony Attard – Mid iOS Developer' },
  { name: 'Jonathan Buttigieg – Mid-Level AI Engineer' },
  { name: 'Carlos Harerimana – Mid-Level Developer' },
  { name: 'Mikhael Cutajar – Junior AI Engineer' },
  { name: 'Luka Djincharadze – Junior AI Engineer' },
  { name: 'Deborah Vella – Mid Development Engineer' },
  { name: 'Aiden Williams - Junior AI Engineer' },
  { name: 'Blaise Izabayo – Mid Frontend Software Developer' },
  { name: 'Yves Tumushimire – Mid-Level Developer' },
  { name: 'Jospin Rugamba Bahizi – Junior Developer' },
  { name: 'Karangwa Ntwali Josue - Mid-level Frontend Engineer' },
  { name: 'Mazzi Muzamiru – Junior Developer' },
  { name: 'Aime Cesaire Musangamfura – Machine Learning Engineer' },
  { name: 'Charles Yusuf - Machine Learning Engineer' },
  { name: 'Moayad Elamin -  Machine Learning Engineer' },
  { name: 'Anna Tenenbaum – Project Manager' },
  { name: 'Keith Vanhear – Director of US Project Management' },
  { name: 'Luke Camilleri – AI Engineer' },
  { name: 'Malik Dawar - Senior Android Developer' },
  { name: 'Boban Mitevski - Senior Android Developer' },
  { name: 'Serhii Harbuz - Senior DevOps Engineer' },
  { name: 'Maciej Przygrodzki - DevOps Manager/Technical Lead' },
  { name: 'Błażej Kwiatkowski - Junior DevOps Engineer' },
  { name: 'Zoltán Máté - Backend Developer' }
];

export const ADVISORY_BOARD = [
  {
    description: (
      <Fragment>
        <p>
          Billy D’Arcy’s career in Telecommunications & ICT spans the past 25
          years. He has occupied various senior management roles across
          organisations such as Cable & Wireless, Eircom and Worldcom,
          Telefonica O2 UK & Ireland. In 2017, he became CEO of BAI
          Communications where he expanded BAI’s capabilities across Europe by
          helping the UK Government, Transport Authorities, Mobile Network
          Operators and Telecoms Operators develop world class infrastructure.
          Before joining BAI, Billy worked for Telefonica O2 UK as Managing
          Director for Telefonica O2 UK’s Enterprise & Public Sector Business.
        </p>
        <p>
          Billy has experience successfully leading large, cross functional
          teams to deliver major projects and programs across both the
          Enterprise and Public Sector landscape, with a focus on strategic
          partnering and compliance programs.
        </p>
      </Fragment>
    ),
    name: 'Billy D’Arcy',
    slug: 'billy-darcy',
    role: 'CEO, BAI Communications UK and Ireland'
  },
  {
    description: (
      <Fragment>
        <p>
          Sarah-Jill is a former UK Diplomat and former Chief Security Officer &
          Chief Information Security Officer for Deloitte. Her career in the
          Foreign Office included postings in Montevideo, Brussels, Budapest and
          Washington where she most recently ran a government agency which
          produced high end secure technology and engineering solutions. As
          Security Partner with Deloitte, her responsibilities combined the
          roles of Chief Security Officer & Chief Information Security Officer
          at second line of defence, and Chief Confidentiality & Privacy Officer
          for the UK and Switzerland, and latterly for North West Europe.
        </p>
        <p>
          Sarah-Jill now works as an NED, Adviser and Visiting Professor, with a
          strong background in strategic planning, excellent analytical skills,
          broad experience in security and intelligence, and a passion for
          effective delivery.
        </p>
      </Fragment>
    ),
    name: 'Sarah-Jill Lennard',
    slug: 'sarah-jill-lennard',
    role: 'Former UK Diplomat, Security & Risk Expert'
  },
  {
    description: (
      <Fragment>
        <p>
          Jane is a seasoned entrepreneur with diverse experience working as a
          founder and CEO of companies that went on to have successful exits
          through acquisition and public trading. During her time as Chief
          Financial Officer of an early-stage medical diagnostics company, she
          raised $60 million and completed eight international acquisitions. The
          company was listed on both the London and Oslo stock exchanges.
        </p>
        <p>
          Jane continues to work with fast growing companies — in interim and
          full-time positions — as a consultant, board member, and angel
          investor. In addition, Jane has served on boards for public and
          private companies, as well as government-related and non-profit
          organizations. She is a frequent speaker and panelist at conferences,
          companies, and universities and is an active volunteer in her
          community.
        </p>
      </Fragment>
    ),
    name: 'Jane McCracken (Karwoski)',
    slug: 'jane-karwoski',
    role: 'Expert, Corporate Growth & Finance'
  },
  {
    description: (
      <Fragment>
        <p>
          Buffy Christie is currently the Head of Global Security at Novelis.
          There she handles the development, implementation, and management of
          their comprehensive Global Security strategy. Security Magazine
          recently named Christie as one of the Most Influential People in
          Security for 2018.
        </p>
        <p>
          Christie has an experienced career in enterprise security, having
          previously served as Senior Director of Investigations at Equifax for
          over five years and Lead of Fraud Operations & Investigations at
          JPMorgan Chase for over a decade.
        </p>
      </Fragment>
    ),
    name: 'Buffy Christie',
    slug: 'buffy-christie',
    role: 'Director of Global Security at Novelis'
  },
  {
    description: (
      <Fragment>
        <p>
          David Garland is the Managing Partner of Second Century Ventures
          (SCV), a venture capital fund focused on promoting innovation in the
          real estate industry. He joined the Advisory Board in 2016 when Trust
          Stamp participated in the National Association of Realtors
          <sup>&reg;</sup> vertical tech accelerator program, REach
          <sup>&reg;</sup>. Dave works to increase the financial value of SCV
          portfolio and REach companies through coaching, mentoring and
          strategic advisory.
        </p>
      </Fragment>
    ),
    name: 'David Garland',
    slug: 'david-garland',
    role: 'MD Second Century Ventures'
  },
  {
    description: (
      <Fragment>
        <p>
          James Gaughran recently retired from a 43-year long career in law
          enforcement and fraud-prevention roles within the United States
          Government.
        </p>
        <p>
          Gaughran entered law enforcement in 1975 as a Special Agent with The
          United States Secret Service (USSS) and served that role for over two
          decades. Throughout his career, Gaughran directly served the
          presidencies of Jimmy Carter and Ronald Reagan as a Secret Service
          Agent, held the position of Financial Fraud Manager for Defense
          Manpower Data Center (DMDC), under the Department of Defense, and
          served as the Deputy Assistant Inspector General at the Department of
          Homeland Security, OIG for ten years. The President's Council on
          Integrity and Efficiency recognized James with several awards during
          his career.
        </p>
      </Fragment>
    ),
    name: 'James Gaughran',
    slug: 'james-gaughran',
    role:
      'Former Deputy Assistant Inspector, U.S. Department of Homeland Security Office of Inspector General'
  },
  {
    description: (
      <Fragment>
        <p>
          Sam Guttman is currently the Chairman of the International Association
          of Financial Crimes Investigators and the CEO and founder of
          FraudSolvers LLC, a provider of fraud prevention consulting and
          services. He formerly served as Inspector in Charge with the United
          States Postal Inspection Service where he lead the agency’s first
          initiatives to use forensic computer analysis of data in investigative
          work, executing an annual budget of over $25 million while supporting
          4,500 people within the USPIS.
        </p>
        <p>
          Guttman has more recently dedicated his expertise to supporting the
          fraud-prevention community, having developed two new certifications
          during his time as Chairman of the Digital Forensics Certification
          Board (DFCB) Advisory Committee and having served a four-year term as
          President of the International Association of Financial Crimes
          Investigators (IAFCI) that ended in 2018.
        </p>
      </Fragment>
    ),
    name: 'Sam Guttman',
    slug: 'sam-guttman',
    role: 'Chairman, International Association of Fraud Investigators'
  },
  {
    description: (
      <Fragment>
        <p>
          Frank Hanna III has over 35 years of experience founding and investing
          in technology and financial services. Hanna co-founded CompuCredit
          Corp., now known as Atlanticus Holdings Corporation (NASDAQ: ATLC). He
          currently advises a number of nonprofit organizations and has received
          multiple awards for Philanthropic Leadership.
        </p>
      </Fragment>
    ),
    name: 'Frank Hanna III',
    slug: 'frank-hanna',
    role: 'Founder, FSH Capital'
  },
  {
    description: (
      <Fragment>
        <p>
          John Leather has over four decades of experience in the payments
          industry, having been a Senior Manager at Lloyds Banking group for
          nearly 35 years. He formerly served as the Head of Business Assurance
          for the Account Switching Programme with the UK Payments Council,
          where his department was responsible for the mobilisation and
          on-boarding of over 17 UK based banks.
        </p>
      </Fragment>
    ),
    name: 'John Leather',
    slug: 'john-leather',
    role: 'Expert, UK Payments Industry'
  },
  {
    description: (
      <Fragment>
        <p>
          Fred Niehaus is currently a Managing Partner with Interactive Global
          Solutions, an international government relations/public affairs and
          business development firm specializing in corporate strategy, economic
          development, legal and regulatory counsel, government affairs,
          grassroots mobilization, public relations and cross-cultural coalition
          building.
        </p>
        <p>
          He previously served as the SVP of Global Government Relations and
          Public Affairs for the Western Union Company, where he developed
          strategies to reach legislators, regulators, attorneys general,
          community advocacy groups, and various other constituencies.
        </p>
        <p>
          Niehaus also has experience serving in a number of governmental
          positions as the Special Assistant for Economic Development and
          Director of OED under Colorado Governor Roy Romer and Deputy Director
          of Commerce and Community Affairs under Illinois Governor James
          Thompson.
        </p>
      </Fragment>
    ),
    name: 'Fred Niehaus',
    slug: 'fred-niehaus',
    role: 'Managing Partner, Interactive Global Solutions'
  },
  {
    description: (
      <Fragment>
        <p>
          Charles Potts currently serves as the SVP and Chief Innovation Officer
          for The Independent Community Bankers of America<sup>&reg;</sup>{ ' ' }
          (ICBA), an organization dedicated to representing interests of the
          community banking industry. Prior to this, Charles held the role of
          Fintech Catalyst for the Atlanta Technology. Development Center (ATDC)
          as a recognized leader in financial technology with 25+ years of
          hands-on experience in building, growing, leading and nurturing
          organizations. In addition to starting his own companies, Potts’
          ability to achieve profitable revenue growth and exceed business
          objectives in a combination of start-up, early-stage and
          public-companies has landed him Executive roles at Jack Henry,
          Goldleaf, Fiserv and most recently First Performance, where he led the
          start-up's Business Development activities.
        </p>
        <p>
          Potts frequently speaks at industry events and is an active member,
          former board member, and former president of The Association for
          Financial Technology Providers (AFT), and a former director for The
          Financial Managers Society
        </p>
      </Fragment>
    ),
    name: 'Charles Potts',
    slug: 'charles-potts',
    role: 'SVP and Chief Innovation Officer at ICBA'
  },
  {
    description: (
      <Fragment>
        <p>
          Frank Tighe is the current Lead Entrepreneur-In-Residence at the
          Atlanta Technology Development Center (ATDC). He is also the Founder
          and Chairman of a consulting firm, TDH Partners, Inc. Tighe has over
          27 years’ experience in executive management, fundraising, sales,
          marketing, and corporate formation.
        </p>
        <p>
          Tighe saw the successful acquisition of his own company in 2010 and
          went on to serve as a Vice President for the acquiring party, Iridex
          Corp (NASDAQ: IRIX) for nearly three years.
        </p>
      </Fragment>
    ),
    name: 'Frank Tighe',
    slug: 'frank-tighe',
    role: 'Lead Entrepreneur in Residence, ATDC'
  },
  {
    description: (
      <Fragment>
        <p>
          Kristin Slink is a proven FinTech leader with expertise in consumer
          lending, operations, user experience and financial platforms. Kristin
          was most recently the co-founder and Chief Product Officer of San
          Diego, California-based LoanHero, which was acquired by LendingPoint
          in early 2018. At LoanHero, Kristin was the product leader and
          architect of the loan origination platform, and worked
          cross-functionally with all business teams and key customers to ensure
          product fit.
        </p>
        <p>
          Kristin has participated on a variety of industry panels spanning from
          POS financing to being a female founder in FinTech. In both 2017 and
          2018 Kristin was named one of San Diego’s most influential business
          leaders by the San Diego Business Journal and has been described as a
          torch-bearer for tech, finance and leadership. In addition to starting
          her own companies, Kristin loves to dive into early stage businesses
          helping other entrepreneurs navigate through the product life cycle,
          fundraising and strategy.
        </p>
      </Fragment>
    ),
    name: 'Kristin Slink',
    slug: 'kristin-slink',
    role: 'Advisor'
  }
];

export const OPERATIONS = [
  { name: 'Lance Wilson – Senior Vice President of Finance and Accounting' },
  { name: 'Aaron Patton – Senior Director' },
  { name: 'Amy Kneale - Account Manager' },
  { name: 'Silvia Palova - Senior Accountant' },
  { name: 'Isaac McCarthy – Accounting Manager' },
  { name: 'Nadia Filatova - Account Executive' },
  { name: 'Pierre Remy - Content Marketing Manager' },
  { name: 'Ally Madrone - Director - Sales and Business Development' },
  { name: 'Vince Giganti - Director - Sales and Business Development' },
  { name: 'Tracy Ming – Financial Controller' },
  { name: 'Melissa Schonberger - Sales Development Representative' },
  { name: 'Amberly Mangion – Compliance and Data Protection Manager' },
  { name: 'Rebecca McCarthy – Legal Counsel' },
  { name: 'Marie Claire Mutuyimana – Operations Manager Rwanda' },
  {
    name:
      'Aybuke Canbey - Global HR And Malta Office Coordinator'
  },
  { name: 'Michael Rivera - Customer Service Representative' },
  { name: 'Kelvin Verde  - Customer Service Representative' },
  { name: 'Ana Durana - Customer Service Representative' },
  { name: 'Meliza Concepcion - Customer Service Representative' },
  { name: 'Mary Ann Delos Santos - Customer Service Representative' },
  { name: 'Julius Daga - Customer Service Representative' },
  { name: 'Lucia Martinez Ibañez Research Assistant' },
];

export const CORPORATE_BOARD_SHARED = [
  {
    name: 'Gareth Genner',
    slug: 'gareth-genner',
    role: 'Executive Director'
  },
  {
    name: 'Andrew Gowasack',
    slug: 'andrew-gowasack',
    role: 'Executive Director'
  },
  {
    name: 'Josh Allen',
    slug: 'josh-allen',
    role: 'Executive Director'
  },
  {
    name: 'William ("Bill") McClintock',
    slug: 'bill-mcclintock',
    role: 'Board Chairman'
  }
];

export const CORPORATE_BOARD = [
  {
    description: (
      <Fragment>
        <p>
          Charles Potts is a prominent figure in the financial technology
          sector, bringing over 35 years of practical experience in nurturing
          and leading various organizations. Mr. Potts currently serves as the
          executive vice president and chief innovation officer for the
          Independent Community Bankers of America® (ICBA) where he drives
          ICBA’s innovation initiatives, and financial technology strategies.
          <br />
          Throughout his distinguished career, Mr. Potts played instrumental
          roles in the establishment and growth of fintech startups specializing
          in digital banking, mobile engagement, financial management, and
          payments. Many of these endeavors achieved notable success through
          IPOs or strategic acquisitions. His expertise extends to mergers and
          acquisitions, corporate strategy, business development, and product
          management, particularly in the domain of digital and mobile channels
          and their operational efficiencies.
          <br />
          Mr. Potts began his career in operational roles at numerous banking
          organizations including Citizens & Southern National Bank (now Bank of
          America) and HomeBanc. His financial acumen and leadership skills were
          further demonstrated during his executive roles at established
          companies, including Fiserv, Goldleaf and First Performance, where he
          spearheaded business development activities in his role as executive
          managing director from 2015 to 2019. He continues to be involved in
          several associations like The National Fintech Organization (NFO), The
          Association for Financial Technology Providers (AFT) and The
          Technology Association of Georgia (TAG). In his role as a General
          Partner in BankTech Ventures, LLC, he holds an advisory role in
          multiple startups, further reflecting his commitment to the fintech
          sector.
          <br />
          Mr. Potts received his education from the Georgia Institute of
          Technology and pursued graduate studies at Georgia State University in
          Atlanta. He further enhanced his banking expertise by attending the
          Graduate School of Banking at LSU.
        </p>
      </Fragment>
    ),
    name: 'Charles Potts ',
    slug: 'Charles-Potts',
    role: 'Independent Non-Executive Director'
  },
  {
    name: 'Kristin Stafford',
    role: 'Independent Non-Executive Director',
    slug: 'Kristin-Stafford',
    description: (
      <Fragment>
        <p>
          Kristin Stafford is the Co-Founder and CEO of Vital4, a global
          enterprise offering a cloud-based platform which provides instant data
          screening to support compliance, background screening, due diligence,
          and more. Prior to co-founding Vital4, she co-founded and served as a
          Managing Partner of one of the first independent wholesale
          international background screening firms in the US. The platform was
          sold to Dun and Bradstreet in 2021.
        </p>
        <p>
          With more than twenty years of experience in operations management,
          process architecture, and software development, Kristin has organized
          and managed teams of over 100 employees and consultants. She
          previously managed the financial operations of a large Atlanta-based
          financial services corporation, served as a senior consultant for
          Delta Technology and Northern Trust Bank, and held a management role
          within a start-up division of GE Capital.
        </p>
      </Fragment>
    )
  },
  {
    name: 'Berta Pappenheim',
    role: 'Independent Non-Executive Director',
    slug: 'berta-headshot-600',
    description: (
      <Fragment>
        <p>
          Berta Pappenheim has a background in occupational psychology,
          delivering competency-based assessment programmes in the financial and
          professional services industries. Berta moved into the cybersecurity
          sector where she held the role of Managing Director of a cyber threat
          intelligence consulting firm for four years.
        </p>
        <p>
          Berta holds a Master’s degree in Social Sciences from the University
          of Linköping in Sweden and is currently working towards obtaining a
          Master of Science in Neuroscience at King’s College London.
        </p>
      </Fragment>
    )
  }
];
